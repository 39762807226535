import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators} from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { HiveBeeService, HiveSessionService, EnvironmentService, HiveLoginService } from "hive-bee-angular";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"]
})
export class ChangePasswordComponent implements OnInit {
  passwordForm: FormGroup;
  public isChanging: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    protected hiveBee: HiveBeeService,
    protected environmentService: EnvironmentService,
    protected sessionService: HiveSessionService,
    protected loginService: HiveLoginService,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      currentPassword: new FormControl("", Validators.compose([Validators.required])),
      newPassword: new FormControl("", Validators.compose([Validators.required])),
      confirmPassword: new FormControl("", Validators.compose([Validators.required])),
    });
  }

  passwordsDoMatch() {
    const newPassword = this.passwordForm.controls["newPassword"].value;
    const confirmPassword = this.passwordForm.controls["confirmPassword"].value;

    // If one or the other is empty, we don't show the don't match error
    if (!newPassword || !confirmPassword) {
      return true;
    }

    return newPassword === confirmPassword;
  }

  async changePassword() {
    if (this.isChanging) {
      console.log("Ignoring concurrent change passwords");
      return;
    }
    this.isChanging = true;

    const currentPassword = this.passwordForm.controls["currentPassword"].value;
    const newPassword = this.passwordForm.controls["newPassword"].value;
    const params = [currentPassword, newPassword];

    var beeClient = this.hiveBee.beeClient.getValue();
    await beeClient.actions.send("changePassword", params);
    this.close();
    
    this.loginService.logout();
    
    // not logged in so redirect to login page with the return url
    this.router.navigate(["/login"], {
      queryParams: { returnUrl: decodeURIComponent(this.router.url) }
    });
  }

  close() {
    this.dialogRef.close();
  }
}
