import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { ProtocolService, DatasetService, UserService } from 'src/app/_services';
import { HiveBeeService, HiveSessionService } from 'hive-bee-angular';
import _ from 'lodash';

@Component({
  selector: 'side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  private subscription: Subscription = null;

  // Map of protocol name to array of unique dataset names
  public allDatasets = {};
  public contributorByDatasetId = {};
  public users = [];
  public isContributor: boolean;
  public isReviewer: boolean;
  public hiveUsername: string = "";
  constructor(
    private router: Router,
    protected protocolService: ProtocolService,
    protected datasetService: DatasetService,
    protected userService: UserService,
    protected zone: NgZone,
    protected hiveBee: HiveBeeService,
    protected sessionService: HiveSessionService,
    ) 
  {}

  async ngOnInit() {
    this.sessionService.authenticated.subscribe({
      next: (authenticated) => {
        this.zone.run( async () => {
          if (authenticated) {
            // determine current user roles
            const beeClient = this.hiveBee.beeClient.getValue();
            this.isContributor = await beeClient.auth.hasRole('contributor');
            this.isReviewer = await beeClient.auth.hasRole('reviewer'); 
            this.hiveUsername = this.sessionService._lastBuiltSession.username;
          } else {
            this.isContributor = false;
            this.isReviewer = false;
          }
        });
      }
    });

    this.subscription = combineLatest(this.protocolService.instances, this.datasetService.instances, this.userService.instances)
    .subscribe( ([protocols, datasets, users]) => {
      this.zone.run( () => {
        this.allDatasets = {};

        this.users = users;

        protocols.forEach( (protocol) => {
          this.allDatasets[protocol] = [];
        });

        datasets.forEach( (ds) => {
          
          // find contributor that created the dataset
          let contUser = users.find(
            user => user.userId == ds.contributor
          );
          
          if (contUser) {
            this.contributorByDatasetId[ds.id] = contUser;
          }

          this.allDatasets[ds.protocol].push(ds);
        });
      });
    });
  }

  getProtocols(){
    return _.keys(this.allDatasets);
  }
  getDatasetsForProtocol(protocol){
    return this.allDatasets[protocol];
  }

  displayDatasetName(ds){
   let contUser =  this.contributorByDatasetId[ds.id];


   if (contUser && contUser.hiveName != this.hiveUsername){
      return ds.name + ` (shared by ${contUser.name})`
    }
    
    return ds.name;
  }

  showDataset(dataset){
    this.router.navigate([`studies/dataset/${dataset.id}`]);
  }

  isProtocolEmpty(protocol) {
    return _.isEmpty(this.allDatasets[protocol]);
  }

  expandProtocol(protocol: string){
    return decodeURIComponent(this.router.url).includes('dataset/'+protocol);
  }

  datasetActive(dataset){
    return decodeURIComponent(this.router.url).endsWith("/" + dataset.id);
  }

  atDatasets(){
    return this.router.url.includes('dataset');
  }
  atStudies(){
    return this.router.isActive('studies', false) && !this.router.isActive('add-study', false) && !this.atDatasets();
  }
}
