import { Injectable, NgZone } from '@angular/core';
import { Score, Study } from '../_models';
import { BehaviorSubject } from 'rxjs';
import { AbstractHiveInstancesService, HiveBeeService, HiveReactionsService } from 'hive-bee-angular';
import _ from 'lodash';

@Injectable({ providedIn: "root" })
export class StudyService extends AbstractHiveInstancesService<Study> {
  private idCounter: number = 1;
  private subject: BehaviorSubject<Study[]>;
  private studies: Study[] = [];
  constructor(
    protected hiveBee: HiveBeeService,
    protected hiveReactions: HiveReactionsService,
    protected zone: NgZone
  ) {
    super("pacs", "study", hiveBee, hiveReactions);

    this.subject = new BehaviorSubject<Study[]>(this.studies);

    // Subscribe and update the studies coming from the hive
    this.instances.subscribe(studies => {
      this.zone.run(() => {
        // Keep the placeholders
        var placeholders: Study[] = [];
        this.studies.forEach(study => {
          if (study.isPlaceholder()) {
            placeholders.push(study);
          }
        });

        // Add the hive studies at the end of the list
        this.studies = [...studies.concat(placeholders)];

        // Notify everyone of the change
        this.subject.next(this.studies);
      });
    });
  }

  getStudies(): BehaviorSubject<Study[]> {
    return this.subject;
  }

  createStudyPlaceholder(notes: string, sidesEnabled: string): Study {
    var study = new Study(`${this.idCounter++}`);
    study.notes = notes;
    study.sidesEnabled = sidesEnabled;
    this.studies = [...this.studies];
    this.studies.push(study);
    this.subject.next(this.studies);
    return study;
  }

  saveStudyPlaceholders(studies: Study[], datasetName: string, protocol: string){
    if (_.isEmpty(studies)){
      return;
    }

    let hiveInstances = [];

    for (let s of studies){
      // Check if this is a study that is already saved to the hive since it
      //  has a hive ID.
      if (!s.isPlaceholder()) {
        continue;
      }

      // Check that the study exist in the list
      var index: number = -1;

      for (var idx: number = 0; idx < this.studies.length; idx++) {
        if (this.studies[idx].id == s.id) {
          index = idx;
          break;
        }
      }

      if (index > -1) {
        // Remove the study placeholder from the list of studies
        this.studies = [...this.studies];
        this.studies.splice(index, 1);
        this.subject.next(this.studies);

        // if dataset name or protocol is empty, do not add to dataset
        var hiveInstance = s.toHiveInstance();
        hiveInstance = {state: hiveInstance.state, studyUid: hiveInstance.studyUid, progress: hiveInstance.progress};
        hiveInstances.push(hiveInstance)

      }
    }


    // Send the study information to the hive
    const beeClient = this.hiveBee.beeClient.getValue();

    
    if (_.isEmpty(datasetName) || _.isEmpty(protocol)) {
      beeClient.actions.send("pacs.createStudies", [hiveInstances]);
    } else {
      beeClient.actions.send("pacs.createStudiesAndDataset", [
        hiveInstances,
        datasetName,
        protocol
      ]);
    }

  }

  saveStudyPlaceholder(s: Study, datasetName: string, protocol: string, defaultScores: Score[]) {
    // Check if this is a study that is already saved to the hive since it
    //  has a hive ID.
    if (!s.isPlaceholder()) {
      return;
    }

    // Check that the study exist in the list
    var index: number = -1;

    for (var idx: number = 0; idx < this.studies.length; idx++) {
      if (this.studies[idx].id == s.id) {
        index = idx;
        break;
      }
    }

    if (index > -1) {
      // save notes, datasetName and protocol
      let notes = s.notes;

      // Remove the study placeholder from the list of studies
      this.studies = [...this.studies];
      this.studies.splice(index, 1);
      this.subject.next(this.studies);

      // Send the study information to the hive
      const beeClient = this.hiveBee.beeClient.getValue();

      // if dataset name or protocol is empty, do not add to dataset
      var hiveInstance = s.toHiveInstance();
      hiveInstance = {state: hiveInstance.state, studyUid: hiveInstance.studyUid, progress: hiveInstance.progress};
      
      if (_.isEmpty(datasetName) || _.isEmpty(protocol)) {
        beeClient.actions.send("pacs.createStudy", [hiveInstance, notes, s.sidesEnabled, defaultScores]);
      } else {
        beeClient.actions.send("pacs.createStudyAndDataset", [
          hiveInstance,
          notes,
          datasetName,
          protocol,
          s.sidesEnabled,
          defaultScores
        ]);
      }
    }
  }

  removeStudyPlaceholder(s: Study) {
    if (!s.isPlaceholder()) {
      return;
    }

    // Check that the study exist in the list
    var index: number = -1;

    for (var idx: number = 0; idx < this.studies.length; idx++) {
      if (this.studies[idx].id == s.id) {
        index = idx;
        break;
      }
    }

    if (index > -1) {
      // Remove the study placeholder from the list of studies
      this.studies = [...this.studies];
      this.studies.splice(index, 1);
      this.subject.next(this.studies);
    }
  }

  protected createModelInstance(instance: any): Study {
    var s = new Study(instance.id);
    s.studyUid = instance.properties.studyUid;
    s.state = instance.properties.state;
    s.progress = instance.properties.progress;
    s.dateAdded = instance.created;
    s.studyNumber = "";
    s.notes = "";
    s.contributor = instance.createdBy;
    return s;
  }
}
