import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BasicTextSRStorage, tagMeta, Study } from '../_models';
import { BehaviorSubject } from 'rxjs';
import { AbstractHiveInstancesService, EnvironmentService, HiveBeeService, HiveReactionsService } from 'hive-bee-angular';
import moment from 'moment';
import * as dcmjs from 'dcmjs';

function unmarshal(dataset: any, dest: any) {
  Object.keys(dataset).forEach(key => {
    function processTag(dataset: any, key: string, dest: any) {
      if (!dataset[key] || !dataset[key].Value) {
        return;
      }

      var tag =
        "(" +
        key.substring(0, 4).toLowerCase() +
        "," +
        key.substring(4, 8).toLowerCase() +
        ")";

      Object.keys(dest).forEach(dk => {
        // Breadth first search for a field that matches the tag
        var c = Reflect.getMetadata("constructor", dest, dk);
        var t = Reflect.getMetadata(tagMeta, dest, dk);

        if (t == tag && (c() == Number || c() == String)) {
          var values = dataset[key].Value;

          // Clean up any person names
          values = values.map(v => {
            if (v && v.Alphabetic) {
              if (v.Alphabetic.Family && v.Alphabetic.Given) {
                return v.Alphabetic.Given + " " + v.Alphabetic.Family;
              }

              // TODO suffixes and prefixes

              return v.Alphabetic;
            } else {
              return v;
            }
          });

          if (Array.isArray(dest[dk])) {
            dest[dk] = values;
          } else {
            dest[dk] = values[0];
          }
        } else if (!t) {
          // Recurse into the module
          var newDest;

          if (Array.isArray(dest[dk])) {
            if (dest[dk].length == 0) {
              newDest = new (c())();
              dest[dk].push(newDest);
            } else {
              newDest = dest[dk][0];
            }
          } else {
            if (!dest[dk]) {
              newDest = new (c())();
              dest[dk] = newDest;
            } else {
              newDest = dest[dk];
            }
          }

          processTag(dataset, key, newDest);
        } else if (t == tag && dataset[key].vr == "SQ") {
          dataset[key].Value.forEach(v => {
            var newDest;

            if (Array.isArray(dest[dk])) {
              newDest = new (c())();
              dest[dk].push(newDest);
            } else {
              if (!dest[dk]) {
                newDest = new (c())();
                dest[dk] = newDest;
              } else {
                newDest = dest[dk];
              }
            }

            Object.keys(v).forEach(k => {
              processTag(v, k, newDest);
            });
          });
        }
      });
    }

    processTag(dataset, key, dest);
  });
}

@Injectable({ providedIn: 'root' })
export class AppStudyService extends AbstractHiveInstancesService<Study> {
    private idCounter: number = 1;
    private subject: BehaviorSubject<Study[]>;
    private studies: Study[] = [];
    private studyMetadataCache: any = {}; // Map of studyUid to dicom-web metadata

    constructor(
      protected hiveBee: HiveBeeService,
      protected hiveReactions: HiveReactionsService,
      protected zone: NgZone,
      protected http: HttpClient,
      protected environment: EnvironmentService,
    ) {

      super('evaluation', 'study', hiveBee, hiveReactions);

      this.subject = new BehaviorSubject<Study[]>(this.studies);

      // Subscribe and update the studies coming from the hive
      this.instances.subscribe((studies) => {
        this.zone.run(() => {
          this.studies = studies;

          studies.forEach( (study) => {
            this.updateStudyWithDicomMetadata(study);
          });

          // Notify everyone of the change
          this.subject.next(this.studies);
        });
      });

    }

  getStudies(): BehaviorSubject<Study[]> {
    return this.subject;
  }

  protected createModelInstance(instance: any): Study {
    var s = new Study(instance.id);

    s.studyUid = instance.properties.studyUid;
    s.studyNumber = instance.properties.studyNumber;
    s.notes = instance.properties.notes;
    s.contributor = instance.createdBy;
    s.dateAdded = instance.created;
    s.shares = instance.properties.shares;
    s.log = instance.properties.log;
    s.sidesEnabled = instance.properties.sidesEnabled;
    
    return s;
  }

  private updateStudyWithDicomMetadata(s) {
    if (this.studyMetadataCache[s.studyUid]) {
      let meta = this.studyMetadataCache[s.studyUid];

      meta.forEach( (instance) => {
        // Convert empty values to empty arrays so that dcmjs can naturalize it
        // This could be a quirk in the way that Orthanc marshals dicom-web output
        Object.keys(instance).forEach( (key) => {
          if (!instance[key].Value) {
            instance[key].Value = [];
          }
        });

        try {
          // Get the dicom data as an Object
          let dataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset(
            instance
          );

          if (typeof dataset.StudyDate === 'string') {
            s.studyDate = moment(dataset.StudyDate).format();
          }

          let modality = ['CR', 'CT', 'MR'].find( (m) => m == dataset.Modality );
          if (modality) {
            s.modality = modality;
          }
        } catch (e) {
          console.error(e);
        }
      });
    } else {
      var pacsUrl = this.environment.envData.pacsUrl;

      var httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + window['accessToken'],
          'Accept': 'application/dicom+json'
        })
      };

      this.http.get(pacsUrl + '/dicom-web/studies/' + s.studyUid + '/metadata', httpOptions).subscribe( (resp) => {
        this.studyMetadataCache[s.studyUid] = resp;
        this.updateStudyWithDicomMetadata(s);
        this.subject.next(this.studies);
      });
    }
  }
  
  async downloadStudy(studyUid: string, studyNumber: string, protocol: string) {
    var httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + window["accessToken"],
        Accept: "application/dicom+json"
      })
    };

    var pacsUrl = this.environment.envData.pacsUrl;

    try {
      var seriesResp: any = await this.http
        .get(
          pacsUrl + "/dicom-web/studies/" + studyUid + "/series",
          httpOptions
        )
        .toPromise();

      let downloadSeries = [];
      seriesResp.forEach(series => {
        // Not all series will be structured reports, but enough commonality in the basic elements
        //  that we can unmarshal here
        var btss = new BasicTextSRStorage();
        unmarshal(series, btss);

        let seriesUid = btss.SRDocumentSeries.SeriesInstanceUID;
        let modality = btss.SRDocumentSeries.Modality;

        // We support any general type of report or X-Ray image for now
        if (
          modality == "SR" ||
          modality == "CR" ||
          modality == "HC" ||
          modality == "DX"
        ) {
          downloadSeries.push(
            this.http
              .get(
                pacsUrl +
                  "/dicom-web/studies/" +
                  studyUid +
                  "/series/" +
                  seriesUid +
                  "/metadata",
                httpOptions
              )
              .toPromise()
          );
        }
      });

      let downloadSeriesMeta = await Promise.all(downloadSeries);
      let downloadSeriesInst = [];
      let seriesModalities = {};

      downloadSeriesMeta.forEach(seriesMeta => {
        seriesMeta.forEach(meta => {
          var btss = new BasicTextSRStorage();
          unmarshal(meta, btss);

          let seriesUid = btss.SRDocumentSeries.SeriesInstanceUID;
          let modality = btss.SRDocumentSeries.Modality;
          seriesModalities[seriesUid] = modality;

          if (
            modality != "SR" ||
            btss.SRDocumentSeries.ProtocolName == protocol
          ) {
            downloadSeriesInst.push(
              this.http
                .get(
                  pacsUrl +
                    "/dicom-web/studies/" +
                    studyUid +
                    "/series/" +
                    seriesUid +
                    "/instances",
                  httpOptions
                )
                .toPromise()
            );
          }
        });
      });

      let downloadInstances = await Promise.all(downloadSeriesInst);
      let imageInstances = [];
      let jsonInstances = [];

      downloadInstances.forEach(instances => {
        instances.forEach(instance => {
          var btss = new BasicTextSRStorage();
          unmarshal(instance, btss);

          let seriesUid = btss.SRDocumentSeries.SeriesInstanceUID;
          let instanceUid = btss.SOPCommon.SOPInstanceUID;
          let modality = seriesModalities[seriesUid];

          if (modality == "SR") {
            jsonInstances.push(
              this.http
                .get(
                  pacsUrl +
                    "/dicom-web/studies/" +
                    studyUid +
                    "/series/" +
                    seriesUid +
                    "/instances/" +
                    instanceUid +
                    "/metadata",
                  httpOptions
                )
                .toPromise()
            );
          } else {
            var options = {
              headers: new HttpHeaders({
                Authorization: "Bearer " + window["accessToken"],
                Accept: "image/png"
              })
            };

            // Disabling the downloading of imagery due to regulatory concerns
            //imageInstances.push(this.http.request('GET', pacsUrl + '/dicom-web/studies/' + studyUid + "/series/" + seriesUid + "/instances/" + instanceUid + "/frames/1/rendered", {responseType: "blob", observe: "response", headers: options.headers}).toPromise());
          }
        });
      });

      let jsonMetadata: any = await Promise.all(jsonInstances);
      jsonMetadata.forEach(jsonMetaList => {
        jsonMetaList.forEach(jsonMeta => {
          var btss = new BasicTextSRStorage();
          unmarshal(jsonMeta, btss);

          var source = "algorithm";
          (btss.SRDocumentGeneral.AuthorObserverSequence || []).forEach(aos => {
            if (aos.ObserverType == "PSN") {
              source = "reviewer";

              if (aos.PersonIdentificationCodeSequence.length) {
                source = aos.PersonIdentificationCodeSequence[0].CodeMeaning;
              }
            }
          });

          let a = document.createElement("a");
          a.setAttribute("style", "visibility: hidden;");
          a.setAttribute(
            "href",
            URL.createObjectURL(new Blob([JSON.stringify(btss, null, "\t")]))
          );
          a.setAttribute("download", studyNumber + "-" + source + ".json"); // TODO counter for multiple SR's
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      });

      let imageNum: number = 1;
      let imageData: any = await Promise.all(imageInstances);
      imageData.forEach(data => {
        let a = document.createElement("a");
        a.setAttribute("style", "visibility: hidden;");
        a.setAttribute("href", URL.createObjectURL(new Blob([data.body])));
        a.setAttribute("download", studyNumber + "-" + imageNum + ".png");
        imageNum++;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    } catch (err) {
      console.log(err);
    }
  }
}

