export class Dataset {
    // instance id
    public id: string;
    public created: string;
    public modified: string;
    public name: string;
    public type: string;
    public protocol: string;
    public studyUids: string[];
    public shares: string[];
    public contributor: string; // hive id of the contributor who created the datasets


    public toHiveInstance(): any {
      return {}; // TODO We don't directly modify a dataset so this should be unneeded
    }
}
