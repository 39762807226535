
export function generateFileDownload(fileName, content) {
    let anchorElem = document.createElement("a");
    anchorElem.setAttribute("style", "visibility: hidden;");
    anchorElem.setAttribute(
      "href",
      URL.createObjectURL(new Blob([JSON.stringify(content, null, "\t")]))
    );
    anchorElem.setAttribute("download", `${fileName}.json`);
    document.body.appendChild(anchorElem);
    anchorElem.click();
    document.body.removeChild(anchorElem);
  }