import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  Input,
  ElementRef
} from "@angular/core";
import { HiveBeeService, HiveSessionService } from "hive-bee-angular";
import { ProtocolService, AppStudyService, DatasetService, UserService, ScoringService} from "../_services";
import { Study, LogEntry, User, Dataset, Score } from "../_models";
import { Subscription, combineLatest } from "rxjs";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { ReviewTooltipComponent } from "../_components/review-tooltip/review-tooltip.component";
import { ShareDatasetTooltipComponent } from "../_components/share-dataset-tooltip/share-dataset-tooltip.component";
import { generateFileDownload } from 'src/app/_helpers/fileUtils';

import _ from "lodash";

export class Item {
  public id: string;
  public name: string;
  public protocol: string;
  public studyUids: string[];
  public studyNumbers: string[];
  public studyCount: number;
  public reviews: "none" | "assigned" | "done";
  public reviewsClicked: boolean;
  public downloading: boolean = false;
  public isScoreExportPending: boolean = false;
  public contributor: string = ""; // name of the contributor who created the dataset
}

@Component({
  selector: "datasets",
  templateUrl: "./datasets.component.html",
  styleUrls: ["./datasets.component.scss"]
})
export class DatasetsComponent implements OnInit, OnDestroy {
  @Input("datasetView") datasetView: boolean = true;

  public iconStates = {};

  private subscription: Subscription;

  // object with keys being protocol names, and value being
  // array of dataset pairs of the form [x, y] where x is dataset name, y is an array of Study objects
  public datasetsByProtocol = {};

  public items: Record<string, Array<Item>> = {};

  constructor(
    public datasetService: DatasetService,
    public protocolService: ProtocolService,
    public userService: UserService,
    public studyService: AppStudyService,
    public scoringService: ScoringService,
    public zone: NgZone,
    public router: Router,
    private hiveBee: HiveBeeService,
    private dialog: MatDialog,
    private sessionService: HiveSessionService
  ) {}

  ngOnInit() {
    this.subscription = combineLatest(
      this.protocolService.instances,
      this.datasetService.instances,
      this.studyService.instances,
      this.userService.instances,
      this.scoringService.instances
    ).subscribe({
      next: ([protocols, datasets, studies, users, scores]: [string[], Dataset[], Study[], User[], Score[]]) => {
        this.zone.run(() => {
          this.datasetsByProtocol = {};

          // Initialize the entries for the known protocols
          protocols.forEach( (protocol: string) => {
            if (!this.items[protocol]) {
              this.items[protocol] = [];
            }

            // TODO REMOVE!
            if (!this.datasetsByProtocol[protocol]) {
              this.datasetsByProtocol[protocol] = [];
            }
          });
          
          // Keep track of untouched items
          Object.keys(this.items).forEach( (protocol: string) => this.items[protocol].forEach( (item: Item) => item["touched"] = false ) );

          // Fill in the dataset information
          datasets.forEach( (dataset: Dataset) => {
            if (!this.items[dataset.protocol]) {
              this.items[dataset.protocol] = [];
            }

            var item: Item = this.items[dataset.protocol].find(
              i => i.id == dataset.id
            );

            if (!item) {
              item = new Item();
              item.name = dataset.name;
              item.protocol = dataset.protocol;
              this.items[dataset.protocol].push(item);
            }

            item.id = dataset.id;
            item["touched"] = true;

            let contUser = users.find(
              user => user.userId == dataset.contributor
            );

            if (contUser) {
              item.contributor = contUser.name;
            }

            item.studyCount = dataset.studyUids.length;
            item.studyUids = dataset.studyUids;
            item.studyNumbers = dataset.studyUids.map( (studyUid: string) => studies.find( (study: Study) => study.studyUid == studyUid ) ).map( (study: Study) => {
              if (study) {
                return study.formatStudyNumber();
              }
              return "";
            });
            item.reviews = "none";

            // TODO REMOVE!
            // For private protocols like the ones conditionally exposed in the protocol service
            if (!this.datasetsByProtocol[dataset.protocol]) {
              this.datasetsByProtocol[dataset.protocol] = [];
            }

            let dsStudies = [];
            dataset.studyUids.forEach( (studyUid: string) => {
              let study = studies.find( (study: Study) => study.studyUid == studyUid);
              if (study) {
                dsStudies.push(study);
                
                var reviewerSet: string[] = [];
                
                study.log.slice().reverse().forEach( (log: LogEntry) => {
                  if (!log.reviewerId || reviewerSet.includes(log.reviewerId)) {
                    return;
                  }
                  
                  reviewerSet.push(log.reviewerId);
                  
                  if (log.status == "In Progress" || log.status == "In Review") {
                    item.reviews = "assigned";
                  }
                });
              }
              
              // TODO show that the study is not visible to the user at the moment
            });
            
            if (item.reviews == "none") {
              // It could be that all reviews are done.
              
              if (dataset.studyUids.map( (studyUid: string) => studies.find( (study: Study) => studyUid == study.studyUid ) ).find( (study: Study) => study != null && study.log.find( (entry: LogEntry) => entry.status == "Done" ))) {
                item.reviews = "done";
              }
            }

            this.datasetsByProtocol[dataset.protocol].push([
              dataset.name,
              dsStudies
            ]);
          });
    
          // Clean up any items that are no longer there.
          // It is known that this will not work if multiple datasets are removed at once.
          // In the vast majority of cases datasets should be deleted only one at a time..
          Object.keys(this.items).forEach( (protocol: string) => this.items[protocol].forEach( (item: Item, index: number) => {
            if (!item["touched"]) {
              this.items[protocol].splice(index, 1);
            }
            
            item["touched"] = undefined;
          }));
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getProtocols() {
    return Object.keys(this.items);
  }

  datasetsPresent() {
    return Object.values(this.items).length > 0;
  }

  // get the expected size of the dataset table
  getTableSize(protocol: string) {
    let size = _.size(this.items[protocol]);
    return 35 + 54 * size + "px";
  }

  showDataset(dataset: Dataset) {
    this.router.navigate([`studies/dataset/${dataset.id}`]);
  }
  
  async downloadDataset(dataset: Item) {
    dataset.downloading = true;
    
    try {
      for (var idx = 0; idx < dataset.studyUids.length; idx++) {
        await this.studyService.downloadStudy(dataset.studyUids[idx], dataset.studyNumbers[idx], dataset.protocol);
      }
    } finally {
      dataset.downloading = false;
    }
  }

  exportScores(dataset: Item) {
    dataset.isScoreExportPending = true;
    
    try {
      for (var idx = 0; idx < dataset.studyUids.length; idx++) {
        let report = this.scoringService.getScoresReport(dataset.studyUids[idx], this.userService.all());
        generateFileDownload(`${dataset.studyNumbers[idx]}-scores`, report);
      }
    } catch (e) {
      console.log(`Could not generate score download for study`);
    }

    dataset.isScoreExportPending = false;
  }

  unshareDataset(item: Item, protocol: string) {
    let user = this.userService.all().find(
      (user: User) => user.hiveName == this.sessionService._lastBuiltSession.username
    );
    
    var beeClient = this.hiveBee.beeClient.getValue();
    beeClient.actions.send("unshareDatasetById", [item.id, user.id]);
  }
  
  onClickShare(dataset: Item, protocol: string, event: MouseEvent) {
    let target = new ElementRef(event.currentTarget);
    let user = this.userService.all().find(
      (user: User) => user.hiveName == this.sessionService._lastBuiltSession.username
    );
    this.dialog.open(ShareDatasetTooltipComponent, {
      width: "250px",
      backdropClass: "no-backdroop",
      panelClass: "share-box",
      data: {
        datasetId: dataset.id,
        datasetName: dataset.name,
        datasetProtocol: protocol,
        thisUser: user,
        alignment: "right",
        trigger: target,
      }
    });
  }

  async showReviewers(item: Item, protocol: string, event: MouseEvent) {
    let studyUids = this.datasetService.all().find( (ds: Dataset) => ds.id == item.id).studyUids;
    let studies = this.studyService.all().filter( (study: Study) => studyUids.find(uid => uid == study.studyUid));
    let user = this.userService.all().find(
      (user: User) => user.hiveName == this.sessionService._lastBuiltSession.username
    );
    let target = new ElementRef(event.currentTarget);
    let dialog = this.dialog.open(ReviewTooltipComponent, {
      width: "250px",
      backdropClass: "no-backdrop",
      panelClass: "share-box",
      data: {
        studyUids: studies.map( (study: Study) => study.studyUid ),
        protocol: protocol,
        thisUser: user,
        trigger: target,
        alignment: "right"
      }
    });
    
    item.reviewsClicked = true;
    dialog.afterClosed().subscribe(() => {
      item.reviewsClicked = false;
    });
  }
}
