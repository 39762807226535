import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { AuthGuard, ReviewerGuard } from './_helpers';
import { AdminComponent} from './admin/admin.component';
import { DatasetsComponent } from './datasets/datasets.component';
import { ContributorGuard} from './_helpers/contributor.guard';

const routes: Routes = [
    { path: 'dashboard', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: ':email/:code/login', component: LoginComponent },
    // Auth Guard for child routes is handled in study-section routing module 
    { path: 'studies', loadChildren: './study-section/study-section.module#StudySectionModule' },
    { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
    { path: 'datasets', component: DatasetsComponent, canActivate: [ContributorGuard] },
    // otherwise redirect to home
    { path: '**', redirectTo: 'dashboard' }
];

export const appRoutingModule = RouterModule.forRoot(routes);
