import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { HiveSessionService, HiveBeeService } from 'hive-bee-angular';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private sessionService: HiveSessionService,
        private hiveBee: HiveBeeService,
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.sessionService.isAuthenticated()) {
            // authorised so return true
            return true;
        }

        if (!this.router.url.startsWith("/login?returnUrl=")) {
          // not logged in so redirect to login page with the return url
          this.router.navigate(['/login'], { queryParams: { returnUrl: decodeURIComponent(state.url) }});
        }

        return false;
    }
}

