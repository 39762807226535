import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  public instancesSide: BehaviorSubject<any>;
  public instancesNoSide: BehaviorSubject<any>;

  private featuresNoSide = {
    "AP/PA": [
      "Image Quality", 
    ],
    "Image quality Osteophytes": [
      "Image quality Osteophytes"
    ],
    "Other pathology": [
      "Other pathology"
    ]
  }
  // Hard-coded for now
  private featuresSide = {
    "JSN (OARSI)": [
      "Lateral",
      "Medial",
    ],
    "Osteophytes": [
      "Lateral tibia",
      "Lateral femur",
      "Medial tibia",
      "Medial femur",
      "Tibial eminence",
    ],
    "Subchondral sclerosis": [
      "Lateral tibia",
      "Lateral femur",
      "Medial tibia",
      "Medial femur",
    ],
    "KL-grade": ["KL-grade"], 
    "LAT": [
      "Proximal",
      "Distal",
    ],
  }; 
  constructor(
    ) {

    this.instancesSide = new BehaviorSubject<any>(this.featuresSide);
    this.instancesNoSide = new BehaviorSubject<any>(this.featuresNoSide);
  }

  getFeaturesSide(){
    return this.instancesSide;
  }

  getFeaturesNoSide(){
    return this.instancesNoSide;
  }

  featureToString(subFeature, feature, side) {
    return `${subFeature}|${feature}|${side}`;
  }

  isValidFeatureString(featureString: string, sidesEnabled: string) {
    let parts = _.split(featureString, '|');
    if (!parts || parts.length !== 3) {
      return false;
    }

    if (!(sidesEnabled === '' || parts[2] === '' || parts[2] === sidesEnabled)) {
      return false;
    }

    if (parts[0] in this.featuresNoSide) {
      //feature with no side
      return this.featuresNoSide[parts[0]].includes(parts[1]) && parts[2] == '';
    } else if (parts[0] in this.featuresSide) {
      return this.featuresSide[parts[0]].includes(parts[1]) && ["L","R",""].includes(parts[2]);
    } else return false;
  }
}
