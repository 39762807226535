import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import {
  HiveBeeService,
  EnvironmentService,
  HiveSessionService,
  HiveLoginService,
  HiveUserTypes
} from "hive-bee-angular";
import { Subscription } from "rxjs";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {
  public emailForm: FormGroup;
  private isResetting = false;
  public subscription: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected hiveBee: HiveBeeService,
    protected environmentService: EnvironmentService,
    protected sessionService: HiveSessionService,
    protected loginService: HiveLoginService
  ) {}

  get f() {
    return this.emailForm.controls;
  }

  async ngOnInit() {
    this.emailForm = this.formBuilder.group({
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
        ])
      )
    });
  }

  async resetPassword() {
    if (this.isResetting) {
      console.log("ignoring concurrent password resets");
      return;
    }
    this.isResetting = true;

    const email = this.emailForm.controls["email"].value;

    const recoveryLinkBase =
      location.protocol +
      "//" + // protocol contains the colon
      location.host +
      "/" +
      encodeURIComponent(email) +
      "/";

    const params = [email, recoveryLinkBase];

    this.hiveBee
      .authenticateBee(this.environmentService.envData)
      .then(async () => {
        var beeClient = this.hiveBee.beeClient.getValue();
        await beeClient.actions.send("passwordRecovery.initEmail", []);
        await beeClient.actions.send(
          "passwordRecovery.recoverPassword",
          params
        );
        this.loginService.logout();
        this.close();
      });
  }

  close() {
    this.dialogRef.close();
  }
}
