import { Component, OnInit, OnDestroy, Inject, ElementRef, NgZone } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatDialogConfig, MatDialogRef } from '@angular/material';
import { Subscription, combineLatest } from 'rxjs';
import { UserService, AppStudyService } from 'src/app/_services';
import { User } from 'src/app/_models/user';
import { Study } from 'src/app/_models';
import { HiveBeeService, HiveSessionService } from 'hive-bee-angular';
import { IHiveSession } from 'hive-bee-ts-models';
import { Router, NavigationStart } from '@angular/router';

export class Item {
  name: string;
  userId: string;
  shared: boolean;
  someshared: boolean;
}

@Component({
  selector: "app-share-tooltip",
  templateUrl: "./share-tooltip.component.html",
  styleUrls: ["./share-tooltip.component.scss"]
})
export class ShareTooltipComponent implements OnInit, OnDestroy {
  private readonly dialModalRef: MatDialogRef<ShareTooltipComponent>;
  private readonly triggerElementRef: ElementRef;

  public studyUids: string[] = [];

  public items: Item[] = [];
  public itemSubscription: Subscription;
  
  // Keep track of the users where an action was invoked to prevent
  //  multiple invocations in interim states.
  // key: userId, value: boolean
  public userActionSent: any = {};

  constructor(
    public router: Router,
    public zone: NgZone,
    public hiveBee: HiveBeeService,
    public userService: UserService,
    public appStudyService: AppStudyService,
    public hiveSession: HiveSessionService,
    @Inject(MAT_DIALOG_DATA)
    public data: { studyUids: string[]; trigger: ElementRef },
    dialModalRef: MatDialogRef<ShareTooltipComponent>
  ) {
    this.dialModalRef = dialModalRef;
    this.triggerElementRef = data.trigger;
    this.studyUids = this.data.studyUids;
  }

  async ngOnInit() {
    // close dialog when leaving current page
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.dialModalRef.close();
      }
    });

    // Put element on top of button
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();
    // initial left corner of the tooltip
    let initialY = rect.bottom - 55;
    // offset based on window size and 410px for modal height
    let offset = 410 - (window.innerHeight - initialY);
    offset = offset > 0 ? offset : 0;
    let topVal = initialY - offset;

    matDialogConfig.position = {
      left: `${rect.left + 45}px`,
      top: `${topVal}px`
    };
    matDialogConfig.width = "300px";
    matDialogConfig.height = "400px";
    this.dialModalRef.updateSize(matDialogConfig.width, matDialogConfig.height);
    this.dialModalRef.updatePosition(matDialogConfig.position);

    this.itemSubscription = combineLatest(
      this.hiveSession.session,
      this.appStudyService.getStudies(),
      this.userService.getUsers()
    ).subscribe(([session, s, u]: [IHiveSession, Study[], User[]]) => {
      this.zone.run(() => {
        var studies = s.filter((study) => !!this.studyUids.find( (uid) => uid == study.studyUid));

        // Only users that are not the current user
        var users = u.filter(user => user.hiveName != session.username);

        users.forEach(user => {
          var i: Item = this.items.find( (i: Item) => i.userId == user.id );
          if (!i) {
            i = new Item();
            this.items.push(i);
          }
          
          i.name = user.name;
          i.userId = user.id;
          
          var studiesSharedWithUser = studies.filter( (study) => !!study.shares.find( (share) => share == user.id ) );
          
          i.shared = studiesSharedWithUser.length > 0;
          i.someshared =
            i.shared && studiesSharedWithUser.length != this.studyUids.length;
        });
      });
    });
  }

  ngOnDestroy() {
    if (this.itemSubscription) {
      this.itemSubscription.unsubscribe();
    }
  }

  // share study with user on checkbox click
  onUserClick(item: Item, event: MouseEvent) {
    event.preventDefault();
    
    // We're only going to allow an action to invoked once when in
    //  an intermediate state per session.
    if (item.someshared && this.userActionSent[item.userId]) {
      return;
    }
    
    this.userActionSent[item.userId] = true;
    
    var action: string;
    
    if (item.someshared || !item.shared) {
      action = "shareStudiesWithUser";
    } else {
      action = "unshareStudiesWithUser";
    }
    
    const beeClient = this.hiveBee.beeClient.getValue();
    beeClient.actions.send(action, [this.studyUids, item.userId]);
    
    item.someshared = true;
  }
}
