import { Component, OnInit, Inject } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import {
  HiveReactionsService,
  HiveBeeService,
  HiveSessionService,
  EnvironmentService,
  HiveLoginService
} from "hive-bee-angular";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit {
  passwordForm: FormGroup;
  public isChanging: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hiveReactions: HiveReactionsService,
    protected hiveBee: HiveBeeService,
    protected environmentService: EnvironmentService,
    protected sessionService: HiveSessionService,
    protected loginService: HiveLoginService
  ) {}

  async ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      email: new FormControl(this.data.email),
      password: new FormControl("", Validators.compose([Validators.required])),
      confirmPassword: new FormControl(
        "",
        Validators.compose([Validators.required])
      )
    });
  }

  passwordsDoMatch() {
    const password = this.passwordForm.controls["password"].value;
    const confirmPassword = this.passwordForm.controls["confirmPassword"].value;

    // If one or the other is empty, we don't show the don't match error
    if (!password || !confirmPassword) {
      return true;
    }

    return password === confirmPassword;
  }

  async resetPassword() {
    if (this.isChanging) {
      console.log("Ignoring concurrent change passwords");
      return;
    }
    this.isChanging = true;

    const password = this.passwordForm.controls["password"].value;

    const params = [this.data.email, parseInt(this.data.code), password];

    this.hiveBee
      .authenticateBee(this.environmentService.envData)
      .then(async () => {
        var beeClient = this.hiveBee.beeClient.getValue();
        await beeClient.actions.send(
          "passwordRecovery.changePasswordWithCode",
          params
        );
        this.loginService.logout();
        this.cancel();
      });
  }

  cancel() {
    this.dialogRef.close();
  }
}
