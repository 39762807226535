import { Component, Inject, NgZone, OnDestroy } from '@angular/core';
import { ProtocolService, DatasetService } from '../_services';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HiveBeeService } from 'hive-bee-angular';

@Component({ selector: 'new-data-set', templateUrl: 'newDataSet.component.html', styleUrls: ['newDataSet.component.scss'] })
export class NewDataSetComponent implements OnDestroy {
    public protocols: string[] = [];
    public selectedProtocol: string = "";
    public chosenName: string = "";
    private protocolServiceSubscription: any;

    constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<NewDataSetComponent>,
      public protocolService: ProtocolService,
      public datasetService: DatasetService,
      public zone: NgZone,
      private hiveBee: HiveBeeService,
      private router: Router,
      ) {

      this.protocolServiceSubscription = protocolService.instances.subscribe({
        next: (protocols) => {
          this.zone.run( () => {
            this.protocols = protocols;
          });
        }
      });
  }

  ngOnDestroy() {
    if (this.protocolServiceSubscription) {
      this.protocolServiceSubscription.unsubscribe();
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (!this.chosenName || !this.selectedProtocol) {
      return;
    }

    const beeClient = this.hiveBee.beeClient.getValue();

    // Assert that the dataset is there or will be created
    beeClient.actions.send('assertDataset', [this.chosenName, this.selectedProtocol, 'Evaluation']); // TODO make the type specified in this dialog
    let datasetId = "";
    // Subscribe and wait for this dataset to be available so that we can assign the studies to it
    let subscription = null;
    let complete = false;
    subscription = this.datasetService.instances.subscribe({
      next: (datasets) => {
        let dataset = datasets.find((dataset) => (dataset.name == this.chosenName && dataset.protocol == this.selectedProtocol));
        if (dataset) {
          beeClient.actions.send('addStudiesToDataset', [this.data.map((s) => s.studyUid), dataset.id]);
          datasetId = dataset.id;
          complete = true;
          if (subscription) {
            subscription.unsubscribe();
          }
        }
      }
    });
    if (complete) {
      subscription.unsubscribe();
    }

    // The dataset and studies should show up on this page eventually
    if (datasetId){
      this.dialogRef.close();
      this.router.navigate(['studies', 'dataset', datasetId]);
    } 
  }
}
