import { Injectable, Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ScoringPanelComponent } from "../_components/scoringPanels/scoringPanel.component";
import { ChestGeneralScoringPanelComponent } from "../_components/scoringPanels/chestGeneral/chestGeneral.component";
import { OsteoArthritisKneeScoringPanelComponent } from "../_components/scoringPanels/osteoArthritisKnee/osteoArthritisKnee.component";

@Injectable({ providedIn: 'root' })
export class ProtocolService {
    public instances: BehaviorSubject<string[]>;
    private protocols: string[] = ["Osteoarthritis Knee", "Chest General"]; // Hard-coded for now, maybe
    constructor(
      ) {

      this.instances = new BehaviorSubject<string[]>(this.protocols);
    }

    public getScoringPanelComponent(protocol: string): Type<ScoringPanelComponent> {
      if (protocol == "Chest General") {
        return ChestGeneralScoringPanelComponent;
      } else if (protocol == "Osteoarthritis Knee") {
        return OsteoArthritisKneeScoringPanelComponent;
      } else {
        return null;
      }
    }
}
