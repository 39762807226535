import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HiveLoginService, HiveLoginCredentials, HiveSessionService, HiveUserTypes } from 'hive-bee-angular';
import { AlertService } from '../_services';
import { MatDialog } from '@angular/material';
import { ForgotPasswordComponent } from '../_components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../_components/reset-password/reset-password.component';
import { IHiveSession } from 'hive-bee-ts-models';
import { HiveTokenTypes } from 'hive-bee-ts-models';

@Component({ templateUrl: 'login.component.html', styleUrls: ['./login.component.scss'] })
export class LoginComponent implements OnInit, OnDestroy {
  
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    signInPressed: boolean = false;
    public invalidSubscription: any;
    public showPassword = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private loginService: HiveLoginService,
        private sessionService: HiveSessionService,
        protected dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.route.params.subscribe( (params: Params) => {
          let email = params['email'];
          let recoveryCode = params['code'];
          if (email && recoveryCode){
            this.dialog.open(ResetPasswordComponent, {
              width: '500px' ,
              data: {email: email, code: recoveryCode}    
            });
          }
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

        this.invalidSubscription = this.loginService.invalid.subscribe(invalid => {
          if (invalid) {
            this.alertService.error("Login Failed");
            this.loading = false;
          }
        });

        this.sessionService.session.subscribe( (session: IHiveSession)=>{
         if (session && session['accessToken'] && session['accessToken'].type == HiveTokenTypes.APP) { 
            this.router.navigate([this.returnUrl]); 
          } 
        });
      
    }

    ngOnDestroy() {
      if (this.invalidSubscription) {
        this.invalidSubscription.unsubscribe();
      }
    }
    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;
      
        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;

        var creds = new HiveLoginCredentials();
        creds.username = this.f.username.value;
        creds.password = this.f.password.value;
        creds.userType = HiveUserTypes.APP;
        this.loginService.login(creds);
    }


    forgotPassword(){
      this.dialog.open(ForgotPasswordComponent, {
        width: '500px'      
      });
    }
}
