import { Component, OnInit, NgZone } from '@angular/core';
import { HiveLoginService, HiveSessionService, HiveBeeService } from 'hive-bee-angular';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { UserService, StudyService } from 'src/app/_services';
import _ from 'lodash';

@Component({
  selector: "header-bar",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  public isContributor: boolean;
  public isReviewer: boolean;
  brandName: string = "Evalytics.ai";
  hive_username = "";
  userName: string = "<Logged Out>";
  public dataSetsActive = false;
  public studiesActive = false;
  private studySubscription: Subscription;
  private userSubscription: Subscription;
  public studiesSize: number = 0;
  constructor(
    private loginService: HiveLoginService,
    private userService: UserService,
    private sessionService: HiveSessionService,
    private studyService: StudyService,
    private zone: NgZone,
    private router: Router,
    private hiveBee: HiveBeeService,
    private dialog: MatDialog
  ) {}

  logout() {
    this.loginService.logout();

    // not logged in so redirect to login page with the return url
    this.router.navigate(["/login"], {
      queryParams: { returnUrl: decodeURIComponent(this.router.url) }
    });
  }

  changePassword() {
    this.dialog.open(ChangePasswordComponent, {
      width: "500px"
    });
  }

  updateActive() {
    if (
      this.router.isActive("datasets", false) ||
      this.router.url.indexOf("/dataset/") != -1
    ) {
      this.dataSetsActive = true;
      this.studiesActive = false;
    } else if (this.router.isActive("studies", false)) {
      this.dataSetsActive = false;
      this.studiesActive = true;
    } else {
      this.dataSetsActive = false;
      this.studiesActive = false;
    }
  }

  ngOnInit() {
    this.sessionService.authenticated.subscribe({
      next: authenticated => {
        this.zone.run(async () => {
          if (authenticated) {
            // determine current user roles
            const beeClient = this.hiveBee.beeClient.getValue();
            this.isContributor = await beeClient.auth.hasRole("contributor");
            this.isReviewer = await beeClient.auth.hasRole("reviewer");

            this.hive_username = this.sessionService._lastBuiltSession.username;

            this.userName = this.hive_username; // Until we can resolve the full name

            this.userService.all().forEach(user => {
              if (user.hiveName == this.hive_username) {
                this.userName = user.name;
              }
            });
          } else {
            this.isContributor = false;
            this.hive_username = "";
            this.userName = "<Logged Out>";
          }
        });
      }
    });

    this.userSubscription = this.userService.getUsers().subscribe({
      next: users => {
        // set username to the currently logged in user
        users.forEach(user => {
          if (user.hiveName == this.hive_username) {
            this.zone.run(() => {
              this.userName = user.name;
            });
          }
        });
      }
    });

    this.updateActive();
    this.router.events.subscribe(this.updateActive.bind(this));

    this.studySubscription = this.studyService.instances.subscribe({
      next: studies => {
        this.zone.run(() => {
          this.studiesSize = _.size(studies);
        });
      }
    });
  }
}
