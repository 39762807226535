import { Component, NgZone, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { WorklistItem, Study } from '../_models';
import { UserService, WorklistService } from '../_services';
import _ from 'lodash';

@Component({
  selector: 'reviewer-dashboard',
  templateUrl: './reviewer-dashboard.component.html',
  styleUrls: ['./reviewer-dashboard.component.scss']
})
export class ReviewerDashboardComponent implements OnInit {

  public worklistItems: WorklistItem[] = [];
  public worklistSubscription: Subscription = null;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected userService: UserService,
    protected worklistService: WorklistService,
    protected zone: NgZone,
    ) { 
    }

  ngOnInit() {
    this.worklistSubscription = this.worklistService.worklist.subscribe({
      next: (worklist) => {
        this.zone.run( () => {
          this.worklistItems = worklist;
        });
      }
    });
  }

  ngOnDestroy() {
    if (!this.worklistSubscription) {
      this.worklistSubscription.unsubscribe();
    }
  }

  getTableSize() {
    return 35 + 54 * this.getSize() + "px";
  }

  getSize() {
    return _.size(this.worklistItems);
  }

  // get contributor name
  getContributorName(study: Study){
    let user = _.find(this.userService.all(), user => user.userId == study.contributor)
    if (user){
      return user.name;
    }
    return ' '
  }

  async showStudy(wli: WorklistItem) {
    this.router.navigateByUrl('studies/worklist/'+wli.protocol+'/study-viewer/'+ wli.study.formatStudyNumber(), {relativeTo: this.route });
  }

}
