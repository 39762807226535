import { Injectable, NgZone } from '@angular/core';
import { AbstractHiveInstancesService, HiveBeeService, HiveReactionsService } from 'hive-bee-angular';
import { Dataset } from '../_models/dataset';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DatasetService extends AbstractHiveInstancesService<Dataset> {
    private subject: BehaviorSubject<Dataset[]>;
    private datasets: Dataset[] = [];
    constructor(
      protected hiveBee: HiveBeeService,
      protected hiveReactions: HiveReactionsService,
      protected zone: NgZone) {

      super('evaluation', 'dataset', hiveBee, hiveReactions);
      this.subject = new BehaviorSubject<Dataset[]>(this.datasets);

      // Subscribe and update the user instances coming from the hive
      this.instances.subscribe((datasets) => {
        this.zone.run(() => {
          this.datasets = datasets;
          this.subject.next(this.datasets);
        });
      });

    }
    
  getDatasets(): BehaviorSubject<Dataset[]> {
    return this.subject;
  }

  protected createModelInstance(instance: any): Dataset {
    var ds = new Dataset();
    ds.id = instance.id 
    ds.name = instance.properties.name;
    ds.protocol = instance.properties.protocol;
    ds.type = instance.properties.type;
    ds.studyUids = instance.properties.studyUids;
    ds.shares = instance.properties.shares;
    ds.contributor = instance.createdBy;
    return ds;
  }
}
