import { Injectable } from '@angular/core';
import { HiveSessionService } from 'hive-bee-angular';
import { Study, LogEntry, WorklistItem, User } from '../_models';
import { AppStudyService } from './appStudy.service';
import { UserService } from './user.service';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WorklistService {
  public worklistItems: WorklistItem[] = [];
  public worklist: BehaviorSubject<WorklistItem[]> = new BehaviorSubject(this.worklistItems);  

  constructor(
    protected sessionService: HiveSessionService,
    protected appStudyService: AppStudyService,
    protected userService: UserService,
  ) {
    combineLatest(this.appStudyService.getStudies(), this.userService.getUsers())
     .subscribe( ([studies, users]) => {
       var thisUser: User = users.find( (user: User) => user.hiveName == this.sessionService._lastBuiltSession.username );
       if (!thisUser) {
         return;
       }
       
       var wliToAdd: WorklistItem[] = [];
       var currWli: WorklistItem[] = [];
       
       studies.forEach( (study: Study) => {
         var protocols = [];
         study.log.slice().reverse().forEach( (log: LogEntry) => {
           if (log.reviewerId != thisUser.id || protocols.includes(log.protocol)) {
             return;
           }
           
           protocols.push(log.protocol);
           
           if (log.status != "In Review" && log.status != "In Progress") {
             return;
           }
           
           var wli: WorklistItem = new WorklistItem();
           wli.study = study;
           wli.protocol = log.protocol;
           
           currWli.push(wli);
             
           // Proceed to check if the worklist has this entry yet
           if (!this.worklistItems.find( (wli: WorklistItem) => wli.study.studyUid == study.studyUid && wli.protocol == log.protocol)) {
              wliToAdd.push(wli);
           }
         });
       });
       
       // Shuffle the new work list items
       for (let i = wliToAdd.length - 1; i > 0; i--) {
         const j = Math.floor(Math.random() * (i + 1));
         [wliToAdd[i], wliToAdd[j]] = [wliToAdd[j], wliToAdd[i]];
       }
       
       // Remove any work list items that are no longer current
       for (var idx = 0; idx < this.worklistItems.length; idx++) {
         if (!currWli.find( (wli: WorklistItem) => wli.study.studyUid == this.worklistItems[idx].study.studyUid && wli.protocol == this.worklistItems[idx].protocol )) {
           this.worklistItems.splice(idx, 1);
         }
       }
    
       // Add the new items to the end to help prevent new items from showing up before the current study
       //  when the user is working through their work list forcing them to go back to the worklist again.
       if (wliToAdd.length > 0) {
         this.worklistItems.push(...wliToAdd);
         this.worklist.next(this.worklistItems);
       }
     });
  }
}
