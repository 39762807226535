import { Injectable, NgZone } from '@angular/core';
import { AbstractHiveInstancesService, HiveBeeService, HiveReactionsService } from 'hive-bee-angular';
import { User } from '../_models/user';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService extends AbstractHiveInstancesService<User> {
    private subject: BehaviorSubject<User[]>;
    private users: User[] = [];
    constructor(
      protected hiveBee: HiveBeeService,
      protected hiveReactions: HiveReactionsService,
      protected zone: NgZone) {

      super('evaluation', 'user', hiveBee, hiveReactions);
      this.subject = new BehaviorSubject<User[]>(this.users);


      // Subscribe and update the user instances coming from the hive
      this.instances.subscribe((users) => {
        this.zone.run(() => {
          this.users = users;
          this.subject.next(this.users);
        });
      });

    }

    getUsers(): BehaviorSubject<User[]> {
      return this.subject;
    }



  protected createModelInstance(instance: any): User {
    var u = new User();
    u.id = instance.id 
    u.userId = instance.properties.userId;
    u.name = instance.properties.name;
    u.hiveName = instance.properties.hiveName;
    u.roles = instance.properties.roles;
    return u;
  }
}
