import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

import { HiveLoginService, HiveSessionService, HiveReactionsService, EnvironmentService } from 'hive-bee-angular';
import { StudyService, AppStudyService, UserService, DatasetService, ScoringService } from './_services';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public loggedIn: boolean = false;
    constructor(
        private router: Router,
        private loginService: HiveLoginService,
        private sessionService: HiveSessionService,
        private reactionsService: HiveReactionsService,
        private environmentService: EnvironmentService,
        private studyService: StudyService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private appStudyService: AppStudyService,
        private userService: UserService,
        private datasetService: DatasetService,
        private scoringService: ScoringService,
    ) {
      if (!this.domSanitizer.bypassSecurityTrustResourceUrl) {
        return;
      }
      this.matIconRegistry.addSvgIcon(
        'MHCStacked',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/Powered by EHC_light_vertical.svg')
      );
      this.matIconRegistry.addSvgIcon(
        `robot`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/robot.svg")
      );
      this.matIconRegistry.addSvgIcon(
        `close_panel`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/close_panel.svg")
      );
      this.matIconRegistry.addSvgIcon(
        `back_arrow`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/back_arrow.svg")
      );
      this.matIconRegistry.addSvgIcon(
        `list`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/list.svg")
      );
      this.matIconRegistry.addSvgIcon(
        `my_list`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/my_list.svg")
      );
      this.matIconRegistry.addSvgIcon(
        `checked_list`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/checked_list.svg")
      );
      this.matIconRegistry.addSvgIcon(
        `unchecked_list`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/unchecked_list.svg")
      );
      this.matIconRegistry.addSvgIcon(
        `c_right`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/chevron_right.svg")
      );
      this.matIconRegistry.addSvgIcon(
        `custom`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/customize.svg")
      );
      this.matIconRegistry.addSvgIcon(
        `user`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/user.svg")
      );
      this.matIconRegistry.addSvgIcon(
        `reviewers`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/reviewers.svg")
      );
      this.matIconRegistry.addSvgIcon(
        `reviewersSome`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/reviewersSome.svg")
      );
      this.matIconRegistry.addSvgIcon(
        'add',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/add.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'share',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/share.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'check',
         this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ok.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'down',
         this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/down.svg')
      );
      this.matIconRegistry.addSvgIcon(
        `alarm`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/alarm.svg")
      );
      this.matIconRegistry.addSvgIcon(
        `info`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/info.svg")
      );
      this.matIconRegistry.addSvgIcon(
        `progress`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/progress.svg")
      );
      this.matIconRegistry.addSvgIcon(
        'view',
         this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/view.svg')
      );
      this.matIconRegistry.addSvgIcon(
        `search`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/search.svg")
      );
      this.matIconRegistry.addSvgIcon(
        `chevron_down`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/chevron_down.svg")
      );
      this.matIconRegistry.addSvgIcon(
        `close`,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/close.svg")
      );
    }

    async ngOnInit() {
        this.loggedIn = this.sessionService.isAuthenticated();
        this.sessionService.accessToken.subscribe({
          next: (token) => {
            this.loggedIn = token && !token.isExpired();

            if (!this.loggedIn) {
              //TODO: Figure out how to redirect without losing initial url
              // this.router.navigate(['/login'], { queryParams: { returnUrl: (new HttpUrlEncodingCodec()).decodeKey(this.router.url) }});
              this.studyService.clear();
              this.appStudyService.clear();
              this.userService.clear();
              this.datasetService.clear();
              this.scoringService.clear();
              window['accessToken'] = null;
              window['userId'] = null;
            } else {
              // Install the access token and user ID on the page for the viewer
              window['accessToken'] = token.token;
              window['userId'] = this.sessionService._lastBuiltSession.username;
              this.studyService.init();
              this.appStudyService.init();
              this.userService.init();
              this.datasetService.init();
              this.scoringService.init();
              this.reactionsService.listenToReactions(this.environmentService.envData.reactions.categories);
            }
          }
        });
    }
    atViewer(){
      return this.router.url.includes('study-viewer')
    }

    sideNavOpened(){
      return !this.atLogin() && this.loggedIn && !this.atViewer()
    }
    atLogin(){
     return this.router.url.includes('login');
    }
    logout() {
        this.loginService.logout();
        
    }
}
