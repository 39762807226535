import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterRouteChange = false;
    private timeout: any = null;
    private isError: boolean = false;
    private isWarning: boolean = false;

    constructor(private router: Router) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert message
                    this.clear();
                }
            }
        });
    }

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message: string, keepAfterRouteChange = false) {
        // Success messages should not clear error or warning messages
        if (this.isError || this.isWarning) {
          return;
        }

        // Set/reset timer to clear the success message
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }

        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ type: 'success', text: message });
        this.timeout = setTimeout(() => {
          this.timeout = null;
          this.clear();
        }, 5000);
    }

    error(message: string, keepAfterRouteChange = false) {
        this.isError = true;
        this.isWarning = false;

        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ type: 'error', text: message });
    }

    warn(message: string, keepAfterRouteChange = false) {
        // Warnings should never erase error messages
        if (this.isError) {
          return;
        }

        this.isError = false;
        this.isWarning = true;

        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }

        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ type: "warning", text: message });
    }

    clear() {
        // clear by calling subject.next() without parameters
        this.isError = false;
        this.isWarning = false;
        this.subject.next();
    }
}

