export class User {
    // instance id
    public id: string;
    public created: string;
    public modified: string;
    // hive user id
    public userId: string;
    public name: string;
    public checked:boolean = false;
    public hiveName: string; // Hive username
    public roles: string[];
  
    toHiveInstance(): any {
      return {userId: this.userId, name: this.name, hiveName: this.hiveName, roles: this.roles};
    }

}
