import { Component, Input, OnInit, OnDestroy, NgZone } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  HiveSessionService,
  HiveBeeService,
  EnvironmentService
} from "hive-bee-angular";
import { ScoringPanelComponent } from "../scoringPanel.component";
import {
  User,
  BasicTextSRStorage,
  tagMeta,
  typesMeta,
  vrMeta,
  sopClassMeta,
  CodingSchemeIdentificationSequence,
  ConceptNameCodeSequence,
  ConceptCodeSequence,
  ContentTemplateSequence,
  ContentSequence,
  AuthorObserverSequence,
  PersonIdentificationCodeSequence
} from "../../../_models";

import "reflect-metadata";
import * as dcmjs from "dcmjs";
import { api } from "dicomweb-client";

// TODO move this functionality to the generated constructors for the various DICOM types
// Type-safe assignment of sub-sets of constructed DICOM types.
// This allows you to construct an object getting all of the default
//  values for properties and then assign in a subset of properties you
//  care about.
function tassign<T>(t: T, s: Partial<T>): T {
  return Object.assign(t, s);
}

// TODO abstract and move this marshaler
// Marshal a DICOM storage object to a dictionary with tag keys and values as dcmjs expects to produce
//  part 10 binary that can be stored via dicom-web protocol.
function marshal(
  obj: any,
  dict: any,
  validation: Array<string>,
  path?: string,
  prettyPath?: string
) {
  if (!obj) {
    return;
  }
  if (!path) {
    path = "";
  }
  if (!prettyPath) {
    prettyPath = "";
  }

  Object.keys(obj).forEach((key: string) => {
    var tag = Reflect.getMetadata(tagMeta, obj, key);
    if (!tag) {
      // This is a module, recurse immediately
      marshal(obj[key], dict, validation, "{" + key, key + ".");
      return;
    }

    var types = Reflect.getMetadata(typesMeta, obj, key);
    var thisPath = path + "," + tag;

    var values = obj[key];
    // Skip nulls, undefined and empty arrays
    if (
      typeof values == "undefined" ||
      (typeof values == "object" && !values) ||
      (Array.isArray(values) && values.length == 0)
    ) {
      if (types.includes(path + ",1}") || types.includes(path + ",2}")) {
        validation.push(
          "Validation error: " +
            prettyPath +
            key +
            " requires a value. Found: " +
            values
        );
      }
      return;
    }

    // dcmjs formats the tags differently
    tag = tag
      .toUpperCase()
      .split("(")[1]
      .split(")")[0]
      .replace(",", "");

    if (!Array.isArray(values)) {
      values = [values];
    }

    // Check for zero values for required items
    if (types.includes(path + ",1}")) {
      values.forEach(v => {
        if (!v) {
          validation.push(
            "Validation Error: " +
              prettyPath +
              key +
              " requires a non empty value."
          );
        }
      });
    }

    var vr = Reflect.getMetadata(vrMeta, obj, key);
    if (vr != "SQ" && values.length > 0) {
      dict[tag] = { vr, Value: values };
    } else if (values.length > 0) {
      dict[tag] = { vr, Value: [] };
      values.forEach(v => {
        var subdict = {};
        marshal(v, subdict, validation, thisPath, prettyPath + key + ".");
        dict[tag].Value.push(subdict);
      });
    }
  });
}

function unmarshal(dataset: any, dest: any) {
  Object.keys(dataset).forEach(key => {
    function processTag(dataset: any, key: string, dest: any) {
      if (!dataset[key] || !dataset[key].Value) {
        return;
      }

      var tag =
        "(" +
        key.substring(0, 4).toLowerCase() +
        "," +
        key.substring(4, 8).toLowerCase() +
        ")";

      Object.keys(dest).forEach(dk => {
        // Breadth first search for a field that matches the tag
        var c = Reflect.getMetadata("constructor", dest, dk);
        var t = Reflect.getMetadata(tagMeta, dest, dk);

        if (t == tag && (c() == Number || c() == String)) {
          var values = dataset[key].Value;

          // Clean up any person names
          values = values.map(v => {
            if (v && v.Alphabetic) {
              if (v.Alphabetic.Family && v.Alphabetic.Given) {
                return v.Alphabetic.Given + " " + v.Alphabetic.Family;
              }

              // TODO suffixes and prefixes

              return v.Alphabetic;
            } else {
              return v;
            }
          });

          if (Array.isArray(dest[dk])) {
            dest[dk] = values;
          } else {
            dest[dk] = values[0];
          }
        } else if (!t) {
          // Recurse into the module
          var newDest;

          if (Array.isArray(dest[dk])) {
            if (dest[dk].length == 0) {
              newDest = new (c())();
              dest[dk].push(newDest);
            } else {
              newDest = dest[dk][0];
            }
          } else {
            if (!dest[dk]) {
              newDest = new (c())();
              dest[dk] = newDest;
            } else {
              newDest = dest[dk];
            }
          }

          processTag(dataset, key, newDest);
        } else if (t == tag && dataset[key].vr == "SQ") {
          dataset[key].Value.forEach(v => {
            var newDest;

            if (Array.isArray(dest[dk])) {
              newDest = new (c())();
              dest[dk].push(newDest);
            } else {
              if (!dest[dk]) {
                newDest = new (c())();
                dest[dk] = newDest;
              } else {
                newDest = dest[dk];
              }
            }

            Object.keys(v).forEach(k => {
              processTag(v, k, newDest);
            });
          });
        }
      });
    }

    processTag(dataset, key, dest);
  });
}

@Component({
  templateUrl: "./chestGeneral.component.html",
  styleUrls: ["./chestGeneral.component.scss"]
})
export class ChestGeneralScoringPanelComponent
  implements OnInit, OnDestroy, ScoringPanelComponent {

  @Input() studyInstanceUid: string;
  @Input() review: boolean;
  @Input() user: User;

  score: any = {};
  scoreComparison: any = {};

  constructor(
    private sessionService: HiveSessionService,
    private environment: EnvironmentService,
    private zone: NgZone,
    private hiveBee: HiveBeeService,
    private http: HttpClient,
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  save(): Promise<any> {
    var sr = new BasicTextSRStorage();

    // TODO these things could be initialized in the constructor for the storage class
    tassign(sr.SOPCommon, {
      SOPInstanceUID: dcmjs.data.DicomMetaDictionary.uid(),
      SOPClassUID: Reflect.getMetadata(sopClassMeta, sr.constructor)
    });

    var foundPatient = false;

    // Use the viewer to try to find the patient ID
    const cornerstone = document.getElementById("pacs_viewer")["contentWindow"]
      .cornerstone;
    Object.keys(cornerstone.imageCache.imageCache).forEach(imageId => {
      let patientMetadata = cornerstone.metaData.get("patientModule", imageId);
      if (patientMetadata) {
        foundPatient = true;

        if (patientMetadata.patientId) {
          sr.Patient.PatientID = patientMetadata.patientId;
        }
      }
    });

    // We cannot continue if we didn't get the patient ID because it would
    //  make Orthanc unable to relate the SR to the original study.
    if (!foundPatient) {
      return;
    }

    sr.GeneralStudy.StudyInstanceUID = this.studyInstanceUid;
    tassign(sr.GeneralEquipment, {
      Manufacturer: "Emids Technologies Ltd.",
      ManufacturerModelName: "AI Workbench",
      SoftwareVersions: ["1.0.0"] // TODO put the current version in there
    });

    tassign(sr.SRDocumentSeries, {
      Modality: "SR",
      SeriesInstanceUID: dcmjs.data.DicomMetaDictionary.uid(),
      SeriesNumber: "99",
      ProtocolName: "Chest General",
      // TODO get the user ID using the normal means
      SeriesDescription: window["userId"] + " - Chest General"
    });

    tassign(sr.SRDocumentGeneral, {
      VerificationFlag: "UNVERIFIED",
      CompletionFlag: "COMPLETE",
      InstanceNumber: "1",
      ContentDate: dcmjs.data.DicomMetaDictionary.date(),
      ContentTime: dcmjs.data.DicomMetaDictionary.time(),
      AuthorObserverSequence: [
        tassign(new AuthorObserverSequence(), {
          ObserverType: "PSN",
          PersonName: this.user.name,
          PersonIdentificationCodeSequence: [
            tassign(new PersonIdentificationCodeSequence(), {
              LongCodeValue: this.user.userId,
              CodeMeaning: this.user.name
            })
          ]
        })
      ]
    });

    // TODO sr.SRDocumentContent.ConceptCodeSequence
    // TODO sr.SRDocumentContent.ReferencedSOPSequence
    // TODO sr.SRDocumentContnet.ReferencedFrameOfReferenceUID (take the value from the existing series frame of reference)
    sr.SRDocumentContent.TemporalRangeType = "POINT";

    sr.SOPCommon.CodingSchemeIdentificationSequence.push(
      tassign(new CodingSchemeIdentificationSequence(), {
        CodingSchemeDesignator: "99MAC",
        CodingSchemeName: "Codes used for Chest General",
        CodingSchemeResponsibleOrganization: "https://emids.com",
        CodingSchemeVersion: "0",
        CodingSchemeUID: "1.2.826.0.1.3680043.8.498.10386482941864"
      })
    );

    sr.SRDocumentContent.ConceptNameCodeSequence.push(
      tassign(new ConceptNameCodeSequence(), {
        CodeMeaning: "Imaging Measurement Report",
        CodeValue: "126000",
        CodingSchemeDesignator: "DCM"
      })
    );

    sr.SRDocumentContent.ContentTemplateSequence.push(
      tassign(new ContentTemplateSequence(), {
        MappingResource: "DCMR",
        TemplateIdentifier: "1500"
      })
    );

    sr.SRDocumentContent.ContinuityOfContent = "SEPARATE";

    var scores: Array<[any, string, string]> = [
      [this.score.pneumonia, "Pneumonia", "M100-6"],
      [this.score.consolidation, "Consolidation", "M100-8"],
      [this.score.pneumothorax, "Pneumothorax", "M100-7"],
      [this.score.mass, "Mass", "M100-4"],
      [this.score.infiltration, "Infiltration", "M100-3"],
      [this.score.atelectasis, "Atelectasis", "M100-0"],
      [this.score.cardiomegaly, "Cardiomegaly", "M100-1"]
    ];

    sr.SRDocumentContent.ValueType = "TEXT";
    scores.forEach(score => {
      if (score[0]) {
        sr.SRDocumentContent.ContentSequence.push(
          tassign(new ContentSequence(), {
            // TODO ReferencedSOPSequence to refer to the original instance UID and SOP Class
            // TODO ReferencedFrameOfReferenceUID to refer to the original series frame of reference
            TemporalRangeType: "POINT",
            RelationshipType: "CONTAINS",
            ContinuityOfContent: "SEPARATE",
            ValueType: "TEXT",
            TextValue: score[1] + " detected",
            ConceptCodeSequence: [
              tassign(new ConceptCodeSequence(), {
                CodeValue: score[2],
                CodingSchemeDesignator: "99MAC",
                CodingSchemeVersion: "0",
                CodeMeaning: "FINDING"
              })
            ]
          })
        );
      }
    });

    // Set up the File Meta (Group 2) portion of the payload
    const fileMetaInformationVersionArray = new Uint8Array(2);
    fileMetaInformationVersionArray[1] = 1;
    const dicomDict = new dcmjs.data.DicomDict(
      dcmjs.data.DicomMetaDictionary.denaturalizeDataset({
        FileMetaInformationVersion: fileMetaInformationVersionArray.buffer,
        MediaStorageSOPClassUID: sr.SOPCommon.SOPClassUID,
        MediaStorageSOPInstanceUID: sr.SOPCommon.SOPInstanceUID,
        TransferSyntaxUID: "1.2.840.10008.1.2.1",
        ImplementationClassUID: dcmjs.data.DicomMetaDictionary.uid(),
        ImplementationVersionName: "dcmjs-0.0"
      })
    );

    var validation = [];
    marshal(sr, dicomDict.dict, validation);
    if (validation.length > 0) {
      console.log("Structured report has validation errors: ", validation);
    }

    const part10Buffer = dicomDict.write();

    const config = {
      url: this.environment.envData.pacsUrl + "/dicom-web",
      headers: { Authorization: "Bearer " + window["accessToken"] }
    };

    const dicomWeb = new api.DICOMwebClient(config);
    const options = {
      datasets: [part10Buffer]
    };

    return dicomWeb.storeInstances(options);
  }

  setValue(key, event) {
    this.score[key] = event.checked;
  }

  async load() {
    if (this.review) {
      return true;
    }

    this.scoreComparison = {};

    var pacsUrl = this.environment.envData.pacsUrl;

    var httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + window["accessToken"],
        Accept: "application/dicom+json"
      })
    };
    var resp: any = await this.http
      .get(
        pacsUrl + "/dicom-web/studies/" + this.studyInstanceUid + "/series",
        httpOptions
      )
      .toPromise();

    if (resp) {
      let srSeries = [];
      resp.forEach(series => {
        var btss = new BasicTextSRStorage();
        unmarshal(series, btss);

        let seriesUid = btss.SRDocumentSeries.SeriesInstanceUID;
        let modality = btss.SRDocumentSeries.Modality;

        // Filter on only structured report series for this study
        if (modality == "SR") {
          srSeries.push(
            this.http
              .get(
                pacsUrl +
                  "/dicom-web/studies/" +
                  this.studyInstanceUid +
                  "/series/" +
                  seriesUid +
                  "/metadata",
                httpOptions
              )
              .toPromise()
          );
        }
      });

      let srSeriesMetadata = await Promise.all(srSeries);

      let protocolSeriesInstances = [];

      srSeriesMetadata.forEach(metadata => {
        metadata.forEach(seriesInstance => {
          var btss = new BasicTextSRStorage();
          unmarshal(seriesInstance, btss);

          let seriesUid = btss.SRDocumentSeries.SeriesInstanceUID;

          if (btss.SRDocumentSeries.ProtocolName == "Chest General") {
            protocolSeriesInstances.push(
              this.http
                .get(
                  pacsUrl +
                    "/dicom-web/studies/" +
                    this.studyInstanceUid +
                    "/series/" +
                    seriesUid +
                    "/instances",
                  httpOptions
                )
                .toPromise()
            );
          }
        });
      });

      let instances = await Promise.all(protocolSeriesInstances);
      let instanceMetadata = [];

      instances.forEach(metadata => {
        metadata.forEach(instance => {
          var btss = new BasicTextSRStorage();
          unmarshal(instance, btss);

          let seriesUid = btss.SRDocumentSeries.SeriesInstanceUID;
          let instanceUid = btss.SOPCommon.SOPInstanceUID;

          instanceMetadata.push(
            this.http
              .get(
                pacsUrl +
                  "/dicom-web/studies/" +
                  this.studyInstanceUid +
                  "/series/" +
                  seriesUid +
                  "/instances/" +
                  instanceUid +
                  "/metadata",
                httpOptions
              )
              .toPromise()
          );
        });
      });

      let metadata = await Promise.all(instanceMetadata);

      metadata.forEach(m => {
        m.forEach(instance => {
          var btss = new BasicTextSRStorage();
          unmarshal(instance, btss);

          var source = "AI";
          (btss.SRDocumentGeneral.AuthorObserverSequence || []).forEach(aos => {
            if (aos.ObserverType == "PSN") {
              source = "reviewer";
            }
          });

          let thisScore = {};
          this.scoreComparison[source] = thisScore;

          btss.SRDocumentContent.ContentSequence.forEach(content => {
            content.ConceptCodeSequence.forEach(conceptCode => {
              thisScore[conceptCode.CodeValue] = true;
            });
          });

          // TODO remove this once we've migrated to the correct concept code sequence (not name sequence)
          btss.SRDocumentContent.ContentSequence.forEach(content => {
            content.ConceptNameCodeSequence.forEach(conceptNameCode => {
              thisScore[conceptNameCode.CodeValue] = true;
            });
          });
        });
      });

      console.log("Score comparison:", this.scoreComparison);
    }
  }
}
