import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { HiveSessionService, HiveBeeService } from 'hive-bee-angular';

@Injectable({ providedIn: 'root' })
export class ReviewerGuard implements CanActivate {
    constructor(
        private router: Router,
        private sessionService: HiveSessionService,
        private hiveBee: HiveBeeService
    ) {

    }

   async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let beeClient = await this.hiveBee.beeClient.getValue();
        if (!beeClient) {
          return false;
        }

        let isReviewer = beeClient.auth.hasRole('reviewer');

        if (!this.sessionService.isAuthenticated()) {
          return false;
        }

        //user has reviewer role
        if (isReviewer){
            return true;
        }

        //user does not have reviewer role so redirect to the dashboard
        this.router.navigate(['/dashboard']);
        return false;
    }
}

