export class Score {
    // instance id
    public id: string;
    public created: string;
    public modified: string;
    public studyUid: string;
    public reviewerId: string; // id of the app user object instance
    public feature: string;
    public value: number;
    toHiveInstance(): any {
      return {studyUid: this.studyUid, receiverId: this.reviewerId, feature: this.feature, value: this.value};
    }

}
