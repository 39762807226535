import { Injectable, NgZone } from '@angular/core';
import { Score } from '../_models/score';

import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AbstractHiveInstancesService, HiveBeeService, HiveReactionsService } from 'hive-bee-angular';
import { User } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class ScoringService extends AbstractHiveInstancesService<Score> {
  private subject: BehaviorSubject<Score[]>;
  private scores: Score[] = [];

  constructor(
    protected hiveBee: HiveBeeService,
    protected hiveReactions: HiveReactionsService,
    protected zone: NgZone) {

    super('evaluation', 'score', hiveBee, hiveReactions);
    this.subject = new BehaviorSubject<Score[]>(this.scores);

    // Subscribe and update the user instances coming from the hive
    this.instances.subscribe((scores) => {
      this.zone.run(() => {
        this.scores = scores;
        this.subject.next(this.scores);
      });
    });

  }

  getScores(): BehaviorSubject<Score[]> {
    return this.subject;
  }

  protected createModelInstance(instance: any): Score {
    var score = new Score();
    score.id = instance.id 
    score.studyUid = instance.properties.studyUid;
    score.reviewerId = instance.properties.reviewerId;
    score.feature = instance.properties.feature;
    score.value = instance.properties.value
    return score;
  }

  async saveScores (studyId: string, scores: Score[]) {
    const beeClient = this.hiveBee.beeClient.getValue();
    await beeClient.actions.send('addScores', [
      studyId, scores
    ]);
  }

  getScoresReport(studyId: string, users: User[]) {
    return _.chain(this.scores)
      .filter(score => score.studyUid === studyId && score.reviewerId !== '')
      .map(score => (
        { reviewer: _.get(_.find(users, { id: score.reviewerId }), 'name', 'Unknown reviewer'), 
          feature: score.feature, 
          value: score.value
        })
      )
      .groupBy("reviewer")
      .map((value, key) => ({ reviewer: key, scores: _.map(value, v => _.pick(v, ['feature', 'value'])) }))
      .value();
  }
}
