import { Component, OnInit, OnDestroy, NgZone, Inject, ElementRef } from '@angular/core';
import { HiveBeeService, HiveSessionService } from 'hive-bee-angular';
import { IHiveSession } from 'hive-bee-ts-models';
import { UserService, AppStudyService } from 'src/app/_services';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material';
import { Subscription, combineLatest } from 'rxjs';
import { Study, LogEntry, User } from 'src/app/_models';
import { Router, NavigationStart } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';

export class Item {
  name: string;
  userId: string;
  reviewing: boolean;
  reviewComplete: boolean;
}

@Component({
  selector: "app-review-tooltip",
  templateUrl: "./review-tooltip.component.html",
  styleUrls: ["./review-tooltip.component.scss"]
})
export class ReviewTooltipComponent implements OnInit, OnDestroy {
  private readonly dialModalRef: MatDialogRef<ReviewTooltipComponent>;
  private readonly triggerElementRef: ElementRef;
  private alignment: "left"|"right"|undefined;

  public protocol: string;
  public thisUser: User;
  public studyUids: string[];
  public allChecked: boolean = false;
  public someChecked: boolean = false;

  public items: Item[] = [];
  private itemSubscription: Subscription;
  
  private routerSubscription: Subscription;
  
  // Keep track of the users where an action was invoked to prevent
  //  multiple invocations in interim states.
  // key: userId, value: boolean
  public userActionSent: any = {};

  constructor(
    public router: Router,
    public zone: NgZone,
    public hiveBee: HiveBeeService,
    public userService: UserService,
    public appStudyService: AppStudyService,
    public sessionService: HiveSessionService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      studyUids: string[];
      protocol: string;
      thisUser: User;
      trigger: ElementRef;
      alignment: "left"|"right"|undefined;
    },
    dialModalRef: MatDialogRef<ReviewTooltipComponent>
  ) {
    this.dialModalRef = dialModalRef;
    this.triggerElementRef = data.trigger;
    this.alignment = data.alignment;
    this.studyUids = this.data.studyUids; 
    this.protocol = this.data.protocol;
    this.thisUser = this.data.thisUser;
  }

  ngOnInit() {
    // close dialog when leaving current page
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.dialModalRef.close();
      }
    });

    // Put element on top of button
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();
    // initial left corner of the tooltip
    let initialY = rect.bottom - 55;
    // offset based on window size and 410px for modal height
    let offset = 410 - (window.innerHeight - initialY);
    offset = offset > 0 ? offset : 0;
    let topVal = initialY - offset;

    matDialogConfig.position = {
      top: `${topVal}px`
    };
    
    if (!this.alignment || this.alignment == "left") {
      matDialogConfig.position.left = `${rect.left + 45}px`;
    } else {
      matDialogConfig.position.left = `${rect.left - 325}px`;
    }
    matDialogConfig.width = "300px";
    matDialogConfig.height = "400px";
    this.dialModalRef.updateSize(matDialogConfig.width, matDialogConfig.height);
    this.dialModalRef.updatePosition(matDialogConfig.position);

    this.itemSubscription = combineLatest(
      this.sessionService.session,
      this.appStudyService.getStudies(),
      this.userService.getUsers(),
    ).subscribe(
      ([session, studies, users]: [IHiveSession, Study[], User[]]) => {
        this.zone.run(() => {
          // Only studies that this dialog is assigned
          studies = studies.filter( (study: Study) => this.studyUids.includes(study.studyUid) );

          users.forEach(user => {
            var i: Item = this.items.find( (i: Item) => i.userId == user.id );
            if (!i) {
              i = new Item();
              this.items.push(i);
            }
            
            i.name = user.name;
            i.userId = user.id;
            
            var latestLogsForStudies: LogEntry[] = studies.map( (study: Study) => study.log.slice().reverse().find( (log: LogEntry) => log.reviewerId == user.id ) );
            
            i.reviewing = !!latestLogsForStudies.find( (log: LogEntry) => log && (log.status == "In Progress" || log.status == "In Review") );
            i.reviewComplete = studies.length == 1 && !!studies.find( (study: Study) => study.log.find( (log: LogEntry) => log.reviewerId == user.id && log.status == "Done" ) );
          });
          this.allChecked = !this.items.find( (item: Item) => !item.reviewing );
          this.someChecked = !!this.items.find( (item: Item) => item.reviewing );
        });
      }
    );
  }

  sameUser(item: Item) {
    return item.reviewing && item.userId === this.thisUser.id;
  };

  ngOnDestroy() {
    if (this.itemSubscription) {
      this.itemSubscription.unsubscribe();
    }
    
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  checkAll(event) {
    event.preventDefault();

    let receiverIds = [];

    // Some clicked and some not, we will try to check them all
    this.someChecked = false;

    if (this.items.find(i => i.reviewing) && this.items.find(i => !i.reviewing)) {
      this.allChecked = true;
    } else {
      this.allChecked = !this.allChecked;
    }

    this.items.forEach(item => {
      
      if ((item.reviewing == this.allChecked) || (item.reviewing && item.userId === this.thisUser.id)) {
        return;
      }
      
      receiverIds.push(item.userId);

      item.reviewing = this.allChecked;
    });

    var action: string;
    
    if (this.allChecked){
      action = "sendStudiesToReviewers";
    } else {
      action = "revokeStudiesFromReviewers";
    }

    const beeClient = this.hiveBee.beeClient.getValue();
    beeClient.actions.send(action, [
      this.studyUids,
      receiverIds,
      this.protocol
    ]);
    
  }




  // share study with user on checkbox click
  onUserClick(item: Item, event: MatCheckboxChange) {
    if (!item.userId || (item.reviewing && item.userId === this.thisUser.id)){
      return;
    }

    item.reviewing = event.checked;    

    if (this.items.find(i => i.reviewing) && this.items.find(i => !i.reviewing)) {
      this.someChecked = true;
      this.allChecked = false;
    } else if (this.items.find(i => i.reviewing)) {
      this.someChecked = false;
      this.allChecked = true;
    } else {
      this.someChecked = false;
      this.allChecked = false;
    }
        
    var action: string;
    

    if (item.reviewing) {
      action = "sendStudiesForReview";
    } else {
      action = "revokeStudiesForReview";
    }
    
    const beeClient = this.hiveBee.beeClient.getValue();
    beeClient.actions.send(action, [
      this.studyUids,
      item.userId,
      this.protocol
    ]);
    
  }
}
