import { Component, Input, OnInit, OnDestroy, NgZone } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  HiveSessionService,
  HiveBeeService,
  EnvironmentService
} from "hive-bee-angular";
import { ScoringPanelComponent } from "../scoringPanel.component";
import {
  User,
  BasicTextSRStorage,
  tagMeta,
  typesMeta,
  vrMeta,
  sopClassMeta,
  CodingSchemeIdentificationSequence,
  ConceptNameCodeSequence,
  ConceptCodeSequence,
  AuthorObserverSequence,
  PersonIdentificationCodeSequence
} from "../../../_models";

import "reflect-metadata";
import * as dcmjs from "dcmjs";
import { api } from "dicomweb-client";
import { combineLatest, Subscription } from 'rxjs';
import { FeatureService, ScoringService, UserService } from 'src/app/_services';
import _ from 'lodash';


// TODO move this functionality to the generated constructors for the various DICOM types
// Type-safe assignment of sub-sets of constructed DICOM types.
// This allows you to construct an object getting all of the default
//  values for properties and then assign in a subset of properties you
//  care about.
function tassign<T>(t: T, s: Partial<T>): T {
  return Object.assign(t, s);
}

enum Sides {
  "",
  "R",
  "L",
}

// TODO abstract and move this marshaler
// Marshal a DICOM storage object to a dictionary with tag keys and values as dcmjs expects to produce
//  part 10 binary that can be stored via dicom-web protocol.
function marshal(
  obj: any,
  dict: any,
  validation: Array<string>,
  path?: string,
  prettyPath?: string
) {
  if (!obj) {
    return;
  }
  if (!path) {
    path = "";
  }
  if (!prettyPath) {
    prettyPath = "";
  }

  Object.keys(obj).forEach((key: string) => {
    var tag = Reflect.getMetadata(tagMeta, obj, key);
    if (!tag) {
      // This is a module, recurse immediately
      marshal(obj[key], dict, validation, "{" + key, key + ".");
      return;
    }

    var types = Reflect.getMetadata(typesMeta, obj, key);
    var thisPath = path + "," + tag;

    var values = obj[key];
    // Skip nulls, undefined and empty arrays
    if (
      typeof values == "undefined" ||
      (typeof values == "object" && !values) ||
      (Array.isArray(values) && values.length == 0)
    ) {
      if (types.includes(path + ",1}") || types.includes(path + ",2}")) {
        validation.push(
          "Validation error: " +
          prettyPath +
          key +
          " requires a value. Found: " +
          values
        );
      }
      return;
    }

    // dcmjs formats the tags differently
    tag = tag
      .toUpperCase()
      .split("(")[1]
      .split(")")[0]
      .replace(",", "");

    if (!Array.isArray(values)) {
      values = [values];
    }

    // Check for zero values for required items
    if (types.includes(path + ",1}")) {
      values.forEach(v => {
        if (!v) {
          validation.push(
            "Validation Error: " +
            prettyPath +
            key +
            " requires a non empty value."
          );
        }
      });
    }

    var vr = Reflect.getMetadata(vrMeta, obj, key);
    if (vr != "SQ" && values.length > 0) {
      dict[tag] = { vr, Value: values };
    } else if (values.length > 0) {
      dict[tag] = { vr, Value: [] };
      values.forEach(v => {
        var subdict = {};
        marshal(v, subdict, validation, thisPath, prettyPath + key + ".");
        dict[tag].Value.push(subdict);
      });
    }
  });
}

// Escape unicode characters that cant be used by the algorithm
function escapeUnicode(i: string): string {
  return i
    .replace(/[^\x00-\x7F]/g, "");

}

// Escape Danish characters to XML/HTML code sequences
function escapeDanish(i: string): string {
  return (i || '')
    .replace(/æ/g, "&aelig;")
    .replace(/Æ/g, "&AElig;")
    .replace(/ø/g, "&oslash;")
    .replace(/Ø/g, "&Oslash;")
    .replace(/å/g, "&aring;")
    .replace(/Å/g, "&Aring;");
}

// Unescape Danish characters to XML/HTML code sequences
function unescapeDanish(i: string): string {
  return (i || '')
    .replace(/&aelig;/g, "æ")
    .replace(/&AElig;/g, "Æ")
    .replace(/&oslash;/g, "ø")
    .replace(/&Oslash;/g, "Ø")
    .replace(/&aring;/g, "å")
    .replace(/&Aring;/g, "Å");
}

function unmarshal(dataset: any, dest: any) {
  Object.keys(dataset).forEach(key => {
    function processTag(dataset: any, key: string, dest: any) {
      if (!dataset[key] || !dataset[key].Value) {
        return;
      }

      var tag =
        "(" +
        key.substring(0, 4).toLowerCase() +
        "," +
        key.substring(4, 8).toLowerCase() +
        ")";

      Object.keys(dest).forEach(dk => {
        // Breadth first search for a field that matches the tag
        var c = Reflect.getMetadata("constructor", dest, dk);
        var t = Reflect.getMetadata(tagMeta, dest, dk);

        if (t == tag && (c() == Number || c() == String)) {
          var values = dataset[key].Value;

          // Clean up any person names
          values = values.map(v => {
            if (v && v.Alphabetic) {
              if (v.Alphabetic.Family && v.Alphabetic.Given) {
                return v.Alphabetic.Given + " " + v.Alphabetic.Family;
              }

              // TODO suffixes and prefixes

              return v.Alphabetic;
            } else {
              return v;
            }
          });

          if (Array.isArray(dest[dk])) {
            dest[dk] = values;
          } else {
            dest[dk] = values[0];
          }
        } else if (!t) {
          // Recurse into the module
          var newDest;

          if (Array.isArray(dest[dk])) {
            if (dest[dk].length == 0) {
              newDest = new (c())();
              dest[dk].push(newDest);
            } else {
              newDest = dest[dk][0];
            }
          } else {
            if (!dest[dk]) {
              newDest = new (c())();
              dest[dk] = newDest;
            } else {
              newDest = dest[dk];
            }
          }

          processTag(dataset, key, newDest);
        } else if (t == tag && dataset[key].vr == "SQ") {
          dataset[key].Value.forEach(v => {
            var newDest;

            if (Array.isArray(dest[dk])) {
              newDest = new (c())();
              dest[dk].push(newDest);
            } else {
              if (!dest[dk]) {
                newDest = new (c())();
                dest[dk] = newDest;
              } else {
                newDest = dest[dk];
              }
            }

            Object.keys(v).forEach(k => {
              processTag(v, k, newDest);
            });
          });
        }
      });
    }

    processTag(dataset, key, dest);
  });
}

@Component({
  templateUrl: "./osteoArthritisKnee.component.html",
  styleUrls: ["./osteoArthritisKnee.component.scss"]
})
export class OsteoArthritisKneeScoringPanelComponent
  implements OnInit, OnDestroy, ScoringPanelComponent {
  private subscription: Subscription;

  public featureSet = {};
  @Input() studyInstanceUid: string;
  @Input() review: boolean;
  @Input() user: User;
  @Input() studySidesEnabled: string;
  public invalid = false;
  public reviewers = [];
  public currentReviewer;
  public scoresByReviewer = {};
  public scoreDict: any = {}
  public tabIndexDict: any = {};
  public imageQualityDict = {
    0: "Unusable",
    1: "Acceptable",
    2: "Good"
  };

  score: any = {};
  scoreComparison: any = {};

  constructor(
    private environment: EnvironmentService,
    private hiveBee: HiveBeeService,
    private http: HttpClient,
    protected userService: UserService,
    protected scoringService: ScoringService,
    protected zone: NgZone,
    protected sessionService: HiveSessionService,
    protected featureService: FeatureService,
  ) { }

  ngOnInit() {
    this.subscription = combineLatest(
      this.featureService.getFeaturesNoSide(),
      this.featureService.getFeaturesSide(),
      this.scoringService.instances,
      this.userService.getUsers(),
    ).subscribe(([featuresNoSide, featuresSide, allScores, users]) => {
      this.zone.run(() => {
        // TODO hive_username should be a subscription too probably.

        _.assignIn(this.featureSet, featuresNoSide, featuresSide);

        this.generateTabIndex(featuresNoSide, featuresSide);

        for (let subFeature in featuresNoSide) {
          let featureSet = featuresNoSide[subFeature];

          for (let feature of featureSet) {
            let key = this.featureService.featureToString(subFeature, feature, "");
            this.scoreDict[key] = -1;
          }
        }

        for (let subFeature in featuresSide) {
          let featureSet = featuresSide[subFeature];

          for (let feature of featureSet) {
            if (this.studySidesEnabled === "" || this.studySidesEnabled === "R") {
              let keyRight = this.featureService.featureToString(subFeature, feature, "R");
              this.scoreDict[keyRight] = -1;
            }

            if (this.studySidesEnabled === "" || this.studySidesEnabled === "L") {
              let keyLeft = this.featureService.featureToString(subFeature, feature, "L");
              this.scoreDict[keyLeft] = -1;
            } 
          }
        }

        let filteredScores = _.filter(allScores, score => score.studyUid == this.studyInstanceUid);
        let defaultScores = _.filter(filteredScores, score => score.reviewerId == '');
        this.scoresByReviewer = _.groupBy(filteredScores, score => score.reviewerId);

        if (this.review) {
          let scores = this.scoresByReviewer[this.user.userId];
          if (!scores || scores.length == 0) {
            scores = defaultScores;
          }
          this.updateScores(scores);
        } else {
          // get reviewers
          this.reviewers = [];
          _.keys(this.scoresByReviewer).forEach((id) => {
            let reviewer = _.find(users, user => user.id === id);
            if (reviewer) {
              this.reviewers.push(reviewer);
            }

          });
          if (this.reviewersPresent()) {
            this.currentReviewer = this.reviewers[0].id;
            this.updateScores(this.scoresByReviewer[this.currentReviewer]);
          }
        }
      });
    });

  }

  getTabIndexKey(subfeature, feature, side) {
    return subfeature + '|' + feature + '|' + side;
  }

  generateTabIndex(featuresNoSide, featuresSide) {
    let tabIndex = 1;
    let featureSet = featuresNoSide['AP/PA'];
    featureSet.map(feature => {
      this.tabIndexDict[this.getTabIndexKey('AP/PA', feature, 0)] = tabIndex;
      tabIndex++;
    });

    let featureBatch1 = ['JSN (OARSI)', 'Osteophytes', 'Subchondral sclerosis', 'KL-grade'];
    let featureBatch2 = ['LAT'];

    let sides = [];
    if (this.studySidesEnabled === "" || this.studySidesEnabled === "R")
      sides.push(1);
    
    if (this.studySidesEnabled === "" || this.studySidesEnabled === "L")
      sides.push(2);

    sides.map(side => {
      featureBatch1.map(subFeature => {
        let featureSet = featuresSide[subFeature];

        featureSet.map(feature => {
          this.tabIndexDict[this.getTabIndexKey(subFeature, feature, side)] = tabIndex;
          tabIndex++; 
        })
      })
    });

    featureSet = featuresNoSide['Image quality Osteophytes'];
    featureSet.map(feature => {
      this.tabIndexDict[this.getTabIndexKey('Image quality Osteophytes', feature, 0)] = tabIndex;
      tabIndex++;
    });

    sides.map(side => {
      featureBatch2.map(subFeature => {
        let featureSet = featuresSide[subFeature];
        featureSet.map(feature => {
          this.tabIndexDict[this.getTabIndexKey(subFeature, feature, side)] = tabIndex;
          tabIndex++;
        })
      })
    });

    this.tabIndexDict[this.getTabIndexKey('Other pathology', 'Other pathology', 0)] = tabIndex;
  }

  getTabIndex(subFeature, feature, side) {
    let key = this.getTabIndexKey(subFeature, feature, side);
    return key in this.tabIndexDict ? this.tabIndexDict[key] : 0;
  }

  onReviewerChange(newReviewer) {
    this.updateScores(this.scoresByReviewer[this.currentReviewer]);
  }

  updateScores(newScores) {
    newScores.forEach((score) => {
      this.scoreDict[score.feature] = score.value;
    });
  }

  reviewersPresent() {
    return !_.isEmpty(this.reviewers);
  }

  onFocusGuard(e) {

  }

  invalidScores() {
    return _.includes(_.values(this.scoreDict), -1);
  }

  isSideDisabled(side) {
    switch(this.studySidesEnabled){
      case "": return false;
      case "L": return (side !== 2);
      case "R": return (side !== 1);
      default: return true;
    }
  }

  isValidScore(subFeature, feature, side) {
    return !(this.invalid && !this.isSideDisabled(side) && this.getScore(subFeature, feature, side) === -1);
  }

  onKeyDown(e, subfeature, feature, side, length) {
    if (!this.review || this.isSideDisabled(side)) {
      return;
    }
    let key = _.toNumber(e.key);

    if (_.isNumber(key) && key >= 0 && key < length) {
      this.setNewScore(subfeature, feature, side, key);
    }
    else if (length === 2) {
      if (e.key.toLowerCase() === 'y') {
        this.setNewScore(subfeature, feature, side, 1);
      }
      else if (e.key.toLowerCase() === 'n') {
        this.setNewScore(subfeature, feature, side, 0);
      }
    } else if (length === 3) {
      if (e.key.toLowerCase() === 'u') {
        this.setNewScore(subfeature, feature, side, 0);
      } else if (e.key.toLowerCase() === 'a') {
        this.setNewScore(subfeature, feature, side, 1);
      } else if (e.key.toLowerCase() === 'g') {
        this.setNewScore(subfeature, feature, side, 2);
      }
    }
  }

  // TODO: Find a better way to name the score

  setNewScore(subFeature, feature, side, value) {
    let name = this.featureService.featureToString(subFeature, feature, Sides[side]);
    this.scoreDict[name] = value;
  }


  toggleScore(subFeature, feature, side, value) {
    let score = this.getScore(subFeature, feature, side);

    if (score === value) {
      this.setNewScore(subFeature, feature, side, -1);
    }
    else {
      this.setNewScore(subFeature, feature, side, value);
    }

  }

  nullifyScores() {
    for (let feature in this.scoreDict) {
      this.scoreDict[feature] = -1;
    }
  }

  getScore(subFeature, feature, side) {
    let key = this.featureService.featureToString(subFeature, feature, Sides[side]);
    return this.scoreDict[key];
  }

  ngOnDestroy() {

  }


  async save(): Promise<any> {
    if (this.invalidScores()) {
      this.invalid = true;
      setTimeout(() => { this.invalid = false }, 1000);

      return new Promise(function (resolve, reject) {
        reject(new Error('Score validation failed'));
      });
    }

    // save scores to hive
    let scores = [];

    for (let key in this.scoreDict) {
      let score = {
        reviewerId: this.user.id,
        studyUid: this.studyInstanceUid,
        value: this.scoreDict[key],
        feature: key
      }
      scores.push(score);
    }

    try {
      await this.scoringService.saveScores(this.studyInstanceUid, scores);
    } catch(e) {
      return new Promise(function (resolve, reject) {
        reject(new Error('Saving scores failed'));
      });
    }

    var sr = new BasicTextSRStorage();

    // TODO these things could be initialized in the constructor for the storage class
    tassign(sr.SOPCommon, {
      SOPInstanceUID: dcmjs.data.DicomMetaDictionary.uid(),
      SOPClassUID: Reflect.getMetadata(sopClassMeta, sr.constructor)
    });

    var foundPatient = false;

    // Use the viewer to try to find the patient ID
    const cornerstone = document.getElementById("pacs_viewer")["contentWindow"]
      .cornerstone;
    Object.keys(cornerstone.imageCache.imageCache).forEach(imageId => {
      let patientMetadata = cornerstone.metaData.get("patientModule", imageId);
      if (patientMetadata) {
        foundPatient = true;

        if (patientMetadata.patientId) {
          sr.Patient.PatientID = patientMetadata.patientId;
        }
      }
    });

    // We cannot continue if we didn't get the patient ID because it would
    //  make Orthanc unable to relate the SR to the original study.
    if (!foundPatient) {
      return;
    }

    sr.GeneralStudy.StudyInstanceUID = this.studyInstanceUid;
    tassign(sr.GeneralEquipment, {
      Manufacturer: "Emids Technologies Ltd.",
      ManufacturerModelName: "AI Workbench",
      SoftwareVersions: ["1.0.0"] // TODO put the current version in there
    });

    tassign(sr.SRDocumentSeries, {
      Modality: "SR",
      SeriesInstanceUID: dcmjs.data.DicomMetaDictionary.uid(),
      SeriesNumber: "99",
      ProtocolName: "Osteoarthritis Knee",
      // TODO get the user ID using the normal means
      SeriesDescription: window["userId"] + " - Osteoarthritis Knee"
    });

    tassign(sr.SRDocumentGeneral, {
      VerificationFlag: "UNVERIFIED",
      CompletionFlag: "COMPLETE",
      InstanceNumber: "1",
      ContentDate: dcmjs.data.DicomMetaDictionary.date(),
      ContentTime: dcmjs.data.DicomMetaDictionary.time(),
      AuthorObserverSequence: [
        tassign(new AuthorObserverSequence(), {
          ObserverType: "PSN",
          PersonName: escapeDanish(this.user.name),
          PersonIdentificationCodeSequence: [
            tassign(new PersonIdentificationCodeSequence(), {
              LongCodeValue: this.user.userId,
              CodeMeaning: this.user.hiveName
            })
          ]
        })
      ]
    });

    // TODO sr.SRDocumentContent.ConceptCodeSequence
    // TODO sr.SRDocumentContent.ReferencedSOPSequence
    // TODO sr.SRDocumentContnet.ReferencedFrameOfReferenceUID (take the value from the existing series frame of reference)
    sr.SRDocumentContent.TemporalRangeType = "POINT";

    sr.SOPCommon.CodingSchemeIdentificationSequence.push(
      tassign(new CodingSchemeIdentificationSequence(), {
        CodingSchemeDesignator: "99MAC",
        CodingSchemeName: "Codes used for Osteoarthritis Knee",
        CodingSchemeResponsibleOrganization: "https://emids.com",
        CodingSchemeVersion: "0",
        CodingSchemeUID: "1.2.826.0.1.3680043.8.498.10386482941864"
      })
    );

    sr.SRDocumentContent.ConceptNameCodeSequence.push(
      tassign(new ConceptNameCodeSequence(), {
        CodeMeaning: "Imaging Measurement Report",
        CodeValue: "126000",
        CodingSchemeDesignator: "DCM"
      })
    );

    tassign(sr.SRDocumentContent, {
      // TODO ReferencedSOPSequence to refer to the original instance UID and SOP Class
      // TODO ReferencedFrameOfReferenceUID to refer to the original series frame of reference
      ContinuityOfContent: "SEPARATE",
      TemporalRangeType: "POINT",
      ValueType: "TEXT",
      TextValue: escapeDanish(escapeUnicode(this.score.report)),
      ConceptCodeSequence: [
        tassign(new ConceptCodeSequence(), {
          CodeValue: "201",
          CodingSchemeDesignator: "99MAC",
          CodingSchemeVersion: "0",
          CodeMeaning: "FINDING"
        })
      ]
    });

    // Set up the File Meta (Group 2) portion of the payload
    const fileMetaInformationVersionArray = new Uint8Array(2);
    fileMetaInformationVersionArray[1] = 1;
    const dicomDict = new dcmjs.data.DicomDict(
      dcmjs.data.DicomMetaDictionary.denaturalizeDataset({
        FileMetaInformationVersion: fileMetaInformationVersionArray.buffer,
        MediaStorageSOPClassUID: sr.SOPCommon.SOPClassUID,
        MediaStorageSOPInstanceUID: sr.SOPCommon.SOPInstanceUID,
        TransferSyntaxUID: "1.2.840.10008.1.2.1",
        ImplementationClassUID: dcmjs.data.DicomMetaDictionary.uid(),
        ImplementationVersionName: "dcmjs-0.0"
      })
    );

    var validation = [];
    marshal(sr, dicomDict.dict, validation);
    if (validation.length > 0) {
      console.log("Structured report has validation errors: ", validation);
    }

    const part10Buffer = dicomDict.write();

    const config = {
      url: this.environment.envData.pacsUrl + "/dicom-web",
      headers: { Authorization: "Bearer " + window["accessToken"] }
    };

    const dicomWeb = new api.DICOMwebClient(config);
    const options = {
      datasets: [part10Buffer]
    };

    return dicomWeb.storeInstances(options);
  }

  async load() {
    this.invalid = false;

    this.nullifyScores();
    this.scoreComparison = {};
    this.score = { report: "" };

    var pacsUrl = this.environment.envData.pacsUrl;

    var httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + window["accessToken"],
        Accept: "application/dicom+json"
      })
    };
    var resp: any = await this.http
      .get(
        pacsUrl + "/dicom-web/studies/" + this.studyInstanceUid + "/series",
        httpOptions
      )
      .toPromise();

    if (resp) {
      let srSeries = [];
      resp.forEach(series => {
        var btss = new BasicTextSRStorage();
        unmarshal(series, btss);

        let seriesUid = btss.SRDocumentSeries.SeriesInstanceUID;
        let modality = btss.SRDocumentSeries.Modality;

        // Filter on only structured report series for this study
        if (modality == "SR") {
          srSeries.push(
            this.http
              .get(
                pacsUrl +
                "/dicom-web/studies/" +
                this.studyInstanceUid +
                "/series/" +
                seriesUid +
                "/metadata",
                httpOptions
              )
              .toPromise()
          );
        }
      });

      let srSeriesMetadata = await Promise.all(srSeries);

      let protocolSeriesInstances = [];

      srSeriesMetadata.forEach(metadata => {
        metadata.forEach(seriesInstance => {
          var btss = new BasicTextSRStorage();
          unmarshal(seriesInstance, btss);

          let seriesUid = btss.SRDocumentSeries.SeriesInstanceUID;

          if (btss.SRDocumentSeries.ProtocolName == "Osteoarthritis Knee") {
            protocolSeriesInstances.push(
              this.http
                .get(
                  pacsUrl +
                  "/dicom-web/studies/" +
                  this.studyInstanceUid +
                  "/series/" +
                  seriesUid +
                  "/instances",
                  httpOptions
                )
                .toPromise()
            );
          }
        });
      });

      let instances = await Promise.all(protocolSeriesInstances);
      let instanceMetadata = [];

      instances.forEach(metadata => {
        metadata.forEach(instance => {
          var btss = new BasicTextSRStorage();
          unmarshal(instance, btss);

          let seriesUid = btss.SRDocumentSeries.SeriesInstanceUID;
          let instanceUid = btss.SOPCommon.SOPInstanceUID;

          instanceMetadata.push(
            this.http
              .get(
                pacsUrl +
                "/dicom-web/studies/" +
                this.studyInstanceUid +
                "/series/" +
                seriesUid +
                "/instances/" +
                instanceUid +
                "/metadata",
                httpOptions
              )
              .toPromise()
          );
        });
      });

      let metadata = await Promise.all(instanceMetadata);

      metadata.forEach(m => {
        m.forEach(instance => {
          var btss = new BasicTextSRStorage();
          unmarshal(instance, btss);

          var source = "AI";
          var contentDateTime = (btss.SRDocumentGeneral.ContentDate || "") + "-" + (btss.SRDocumentGeneral.ContentTime || "");
          (btss.SRDocumentGeneral.AuthorObserverSequence || []).forEach(aos => {
            if (aos.ObserverType == "PSN") {
              source = unescapeDanish((aos.PersonName || "Reviewer")) + " (" + contentDateTime + ")";
            }
          });

          var thisScore: any = {};
          this.scoreComparison[source] = thisScore;
          thisScore.report = unescapeDanish(btss.SRDocumentContent.TextValue);
          if (source == "AI") {
            this.score.report = thisScore.report;
          }
        });
      });

      console.log("Score comparison:", this.scoreComparison);
    }
  }

  public comparisonSources(): Array<string> {
    return Object.keys(this.scoreComparison);
  }
}
