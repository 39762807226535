import { Component, OnInit, NgZone } from '@angular/core';
import { ProtocolService, AppStudyService } from '../_services';
import { Router } from '@angular/router';
import { HiveBeeService, HiveSessionService } from 'hive-bee-angular';

@Component({ templateUrl: 'home.component.html', styleUrls: ['./home.component.scss'] })
export class HomeComponent implements OnInit {
    public isReviewer: boolean = false;
    public isContributor: boolean = false;
    constructor(
        public protocolService: ProtocolService,
        public studyService: AppStudyService,
        public router: Router,
        public sessionService: HiveSessionService,
        public hiveBee: HiveBeeService,
        public zone: NgZone
    ) {
    }

    ngOnInit() {
      this.sessionService.authenticated.subscribe({
        next: (authenticated) => {
          this.zone.run( async () => {
            if (authenticated) {
              // determine current user roles
              const beeClient = this.hiveBee.beeClient.getValue();
              this.isReviewer = await beeClient.auth.hasRole('reviewer');
              this.isContributor = await beeClient.auth.hasRole('contributor');
            } else {
              this.isReviewer = false;
              this.isContributor = false;
            }
          });
        }
      });
    }
}

