import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertService } from '../_services';

@Component({ selector: 'alert', templateUrl: 'alert.component.html', styleUrls: ['alert.component.scss'] })
export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;

    constructor(private alertService: AlertService) { }

    ngOnInit() {
        this.subscription = this.alertService.getAlert()
            .subscribe(message => {
                this.message = message;
            });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    getIconName(){
        if (this.message.type == 'error'){
            return 'alarm';
        }
        else if (this.message.type == 'warning'){
            return 'progress';
        }
        else if (this.message.type == 'success'){
            return 'info';
        }
    }
    close() {
         this.alertService.clear();
    }
}
