export class LogEntry {
    public date: string;
    public protocol: string;
    public aiVersion: string;
    public reviewerId: string;
    public status: "Running"|"In Progress"|"In Review"|"Error"|"Warning"|"Complete"|"Cancelled"|"Done";
    public details: string|any;
}

export class Study {
    public id: string;
    public created: string;
    public modified: string;
    public state: string = "Initial";
    public studyUid: string;
    public studyDate: string; // ISO-8601 formatted date
    public modality: string;
    public progress: number = 0.0;
    public error: string;
    public warnings: string[] = [];
    public dateAdded: string; // ISO-8601 formatted date
    public studyNumber: string;
    public notes: string = '';
    public contributor: string; // hive id of the contributor who added the study
    public shares: string[] = [];
    public log: LogEntry[] = [];
    public sidesEnabled: string = '';
    
    constructor(id: string) {
      this.id = id;
    }

    toHiveInstance(): any {
      return {state: this.state, studyUid: this.studyUid, progress: this.progress, shares: this.shares, log: this.log};
    }

    isPlaceholder(): boolean {
      return !isNaN(Number(this.id));
    }

    formatStudyNumber(): string {
      let number = this.studyNumber;
      if (!number) {
        return number;
      }

      let idString  = '';
      let units = number.split('-');
      idString += (units[0].length > 3 ? units[0] : new Array(4 - units[0].length ).join('0')+units[0]);
      idString += '-' + (units[1].length > 4 ? units[1] : new Array(5 - units[1].length ).join('0')+units[1]);
      return idString;
  }
}

