import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HiveSessionService, EnvironmentService } from 'hive-bee-angular';
import { AlertService } from './alert.service';
import { StudyService } from './study.service';
import Resumable from 'resumablejs';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class UploadService {
  private activeUploadCount: number = 0;
  private activeResumable: any = null;

  constructor(
    private sessionService: HiveSessionService,
    private environment: EnvironmentService,
    private alertService: AlertService,
    private studyService: StudyService,
    private http: HttpClient) {
  }

  createResumable(domNode: HTMLElement): any {
    let accessToken = this.sessionService._lastBuiltSession.accessToken.token;

    var token = Math.floor(Math.random() * (1024 * 1024 * 1024)); // TODO proper random token generation
    var pacsUrl = this.environment.envData.pacsUrl;

    var r = new Resumable({
      target: pacsUrl + '/study-upload/' + token,
      fileType: ['zip', "dcm"],
      simultaneousUploads: 3,
      headers: { Authorization: 'Bearer ' + accessToken }
    });

    r.on('error', (message) => {
      console.log("Received an error in resumable upload process:", message);
      this.alertService.error(`Error uploading: ${message}`);
      r["study"].state = "Error";
      r["study"].error = "Error in upload " + message;
    });

    r.on('uploadStart', () => {
      console.log("Starting upload");
      this.alertService.success("Starting upload");
      // Puts a warning on the web browser when the user tries to navigate away from the page
      if (this.activeUploadCount++ == 0) {
        window.addEventListener("beforeunload", this.pageNavigationHandler);
      }
      r["study"].error = "";
      r["study"].state = "Uploading";
    });

    r.on('beforeCancel', () => {
      console.log("Canceling resumable");
      r["study"].state = "Canceled";
    });

    r.on('progress', () => {
      console.log("Progress", r.progress());
      r["study"].progress = r.progress() * 0.4; // Upload is estimated to be around 40% of the work to store a study
    });

    r.on('complete', async () => {
      // If there are no more uploads happening then clear out the warning when the user
      //  tries to navigate away from the page.
      if (--this.activeUploadCount == 0) {
        window.removeEventListener("beforeunload", this.pageNavigationHandler);
      }

      if (r["study"].error || !r["study"].state || r["study"].state != "Uploading") {
        return;
      }

      // So that we only complete once
      r["study"].state = "Upload Complete";

      var httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + accessToken
        })
      };

      r["study"].state = "Storing";
      await this.http.put(pacsUrl + '/study-upload/' + token + '/store', "", httpOptions).toPromise();

      // Poll for the store process to complete every 5 seconds
      var timeout = 6 * 60 * 60; // 6 hour timeout
      var pollInterval;
      pollInterval = setInterval(async () => {
        var result: any = await this.http.get(pacsUrl + '/study-upload/' + token + '/store', httpOptions).toPromise();
        if (timeout-- < 0) {
          clearInterval(pollInterval);
          this.alertService.error(`Timed out waiting for study to be stored`);
          return;
        }

        if (result.StudyId) {
          r["study"].studyUid = result.StudyId;

          if (result.Warnings.length != 0) {
            this.alertService.warn(`Study upload warnings: ${result.Warnings}`);
            r["study"].warnings = result.Warnings;
          }

          await this.http.delete(pacsUrl + '/study-upload/' + token + '/store', httpOptions).toPromise();
          clearInterval(pollInterval);
          this.alertService.success("Study finished processing");
          r["study"].state = "Stored";
          r["study"].progress = 1.0;
          this.studyService.saveStudyPlaceholder(r["study"], r["datasetName"], r["protocol"], r["defaultScores"]);
        } else if (result.Status == "Error" || result.Error) {
          await this.http.delete(pacsUrl + '/study-upload/' + token + '/store', httpOptions).toPromise();
          clearInterval(pollInterval);
          this.alertService.error(`Study upload error: ${result.Error}`);
          r["study"].state = "Error";
          r["study"].error = result.Error;
        }
      }, 5000);
    });

    // Add broth browse on click and DnD on the provided dom node
    r.assignBrowse(domNode, false);
    r.assignDrop(domNode);
    r["domNode"] = domNode;

    this.activeResumable = r;
    return r;
  }

  removeFile(file){
    this.activeResumable.removeFile(file);
  }

  createResumableBatch(domNode: HTMLElement): any {
    let accessToken = this.sessionService._lastBuiltSession.accessToken.token;

    var token = Math.floor(Math.random() * (1024 * 1024 * 1024)); // TODO proper random token generation
    var pacsUrl = this.environment.envData.pacsUrl;

    var r = new Resumable({
      target: pacsUrl + '/study-upload/' + token,
      fileType: ['zip', "dcm"],
      simultaneousUploads: 3,
      headers: { Authorization: 'Bearer ' + accessToken },
    });

    r.on('error', (message) => {
      console.log("Received an error in resumable upload process:", message);
      this.alertService.error(`Error uploading: ${message}`);
      r["studies"].forEach( study => {
        study.state = "Error";
        study.error = "Error in upload " + message;
      });
    });

    r.on('uploadStart', () => {
      console.log("Starting upload");
      this.alertService.success("Starting upload");
      // Puts a warning on the web browser when the user tries to navigate away from the page
      if (this.activeUploadCount++ == 0) {
        window.addEventListener("beforeunload", this.pageNavigationHandler);
      }
      r["studies"].forEach( study => {
        study.state = "Uploading";
        study.error = "";
      });
    });

    r.on('beforeCancel', () => {
      console.log("Canceling resumable");
      r["studies"].forEach( study => {
        study.state = "Canceled";
      });
    });

    r.on('progress', () => {
      console.log("Progress", r.progress());
      r["studies"].forEach( study => {
        study.progress =  r.progress() * 0.4; // Upload is estimated to be around 40% of the work to store a study
      });
    });

    r.on('complete', async () => {
      // If there are no more uploads happening then clear out the warning when the user
      //  tries to navigate away from the page.
      if (--this.activeUploadCount == 0) {
        window.removeEventListener("beforeunload", this.pageNavigationHandler);
      }

      if (_.some(r["studies"], study => study.error || !study.state || study.state != "Uploading")) {
        return;
      }

      // So that we only complete once
      r["studies"].forEach( study => {
        study.state =  "Upload Complete";
      });

      var httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + accessToken
        })
      };

      // So that we only complete once
      r["studies"].forEach( study => {
        study.state =  "Storing";
      });

      await this.http.put(pacsUrl + '/study-upload-batch/' + token + '/store', "", httpOptions).toPromise();

      // Poll for the store process to complete every 5 seconds
      var timeout = 6 * 60 * 60; // 6 hour timeout
      var pollInterval;
      pollInterval = setInterval(async () => {
        var result: any = await this.http.get(pacsUrl + '/study-upload/' + token + '/store', httpOptions).toPromise();
        if (timeout-- < 0) {
          clearInterval(pollInterval);
          this.alertService.error(`Timed out waiting for study to be stored`);
          return;
        }

        if (result.StudyIds && _.size(result.StudyIds) === _.size(r["studies"])) {
          
          result.StudyIds.forEach( (studyUid, index) => {
            r["studies"][index].studyUid = studyUid;
          });

          if (result.Warnings.length != 0) {
            this.alertService.warn(`Study upload warnings: ${result.Warnings}`);
            r["studies"].forEach( study => {
              study.warnings = result.Warnings;
            });
          }

          await this.http.delete(pacsUrl + '/study-upload/' + token + '/store', httpOptions).toPromise();
          clearInterval(pollInterval);
          this.alertService.success(`${_.size(r["studies"])} Studies finished processing`);

          r["studies"].forEach( study => {
            study.state = "Stored";
            study.progress = 1.0;
          });
          this.studyService.saveStudyPlaceholders(r["studies"], r["datasetName"], r["protocol"]);
        } else if (result.Status == "Error" || result.Error) {
          await this.http.delete(pacsUrl + '/study-upload/' + token + '/store', httpOptions).toPromise();
          clearInterval(pollInterval);
          this.alertService.error(`Study upload error: ${result.Error}`);
          r["studies"].forEach( study => {
            study.state = "Error";
            study.error = result.Error;
          });
        }
      }, 5000);
    });

    // Add broth browse on click and DnD on the provided dom node
    r.assignBrowse(domNode, false);
    r.assignDrop(domNode);
    r["domNode"] = domNode;

    this.activeResumable = r;
    return r;
  }




  // Begins the resumable study upload and resets the attached dom node
  //  so that another study upload can be set up.
  startResumable(notes, datasetName, protocol, sidesEnabled, defaultScores): any {
    if (this.activeResumable.files.length == 0) {
      return this.activeResumable;
    }

    this.activeResumable.study = this.studyService.createStudyPlaceholder(notes, sidesEnabled);
    this.activeResumable.datasetName = datasetName;
    this.activeResumable.protocol = protocol;
    this.activeResumable.defaultScores = defaultScores;
    this.activeResumable.study.status = "Uploading";
    this.activeResumable.study.resumable = this.activeResumable; // Circular reference, which is not great.
    this.activeResumable.upload();

    // New dom node and resumable for this drop point to avoid putting more files into this same upload session
    // This is necessary because the resumable library does not currently have a way to deregister the click
    //  listener on the dom node.
    var clone = this.activeResumable.domNode.cloneNode(true) as HTMLElement;
    var p = this.activeResumable.domNode.parentNode;
    p.removeChild(this.activeResumable.domNode);
    p.appendChild(clone);
    return this.createResumable(clone);
  }
  

  
  startResumableBatch(notes, datasetName, protocol): any {
    if (this.activeResumable.files.length == 0) {
      return this.activeResumable;
    }

    console.log('files',this.activeResumable.files);
    let studies = []

    this.activeResumable.files.forEach(file => {
      let study = this.studyService.createStudyPlaceholder(notes, "");
      study["status"] = "Uploading";
      study["resumable"] = this.activeResumable; // Circular reference, which is not great.
      studies.push(study);
    });

    this.activeResumable.studies = studies;
    this.activeResumable.datasetName = datasetName;
    this.activeResumable.protocol = protocol;

    this.activeResumable.upload();

    // New dom node and resumable for this drop point to avoid putting more files into this same upload session
    // This is necessary because the resumable library does not currently have a way to deregister the click
    //  listener on the dom node.
    var clone = this.activeResumable.domNode.cloneNode(true) as HTMLElement;
    var p = this.activeResumable.domNode.parentNode;
    p.removeChild(this.activeResumable.domNode);
    p.appendChild(clone);
    return this.createResumableBatch(clone);
  }

  pageNavigationHandler(event: any) {
    event.preventDefault();
    event.returnValue = "Study uploads are still in progress.";
  }
}

