import { Component, OnInit, OnDestroy, Inject, ElementRef, NgZone } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatDialogConfig, MatDialogRef } from '@angular/material';
import { Subscription, combineLatest } from 'rxjs';
import { UserService, DatasetService } from 'src/app/_services';
import { User } from 'src/app/_models/user';
import { Dataset } from 'src/app/_models';
import { HiveBeeService, HiveSessionService } from 'hive-bee-angular';
import { IHiveSession } from 'hive-bee-ts-models';
import { Router, NavigationStart } from '@angular/router';

export class Item {
  name: string;
  userId: string;
  shared: boolean;
  someshared: boolean;
}

@Component({
  selector: "app-share-dataset-tooltip",
  templateUrl: "./share-dataset-tooltip.component.html",
  styleUrls: ["./share-dataset-tooltip.component.scss"]
})
export class ShareDatasetTooltipComponent implements OnInit, OnDestroy {
  private readonly dialModalRef: MatDialogRef<ShareDatasetTooltipComponent>;
  private readonly triggerElementRef: ElementRef;

  public datasetId: string;
  public datasetName: string;
  public datasetProtocol: string;

  public items: Item[] = [];
  public itemSubscription: Subscription;
  
  // Keep track of the users where an action was invoked to prevent
  //  multiple invocations in interim states.
  // key: userId, value: boolean
  public userActionSent: any = {};

  constructor(
    public router: Router,
    public zone: NgZone,
    public hiveBee: HiveBeeService,
    public userService: UserService,
    public datasetService: DatasetService,
    public hiveSession: HiveSessionService,
    @Inject(MAT_DIALOG_DATA)
    public data: { datasetName: string; datasetProtocol: string; datasetId: string, trigger: ElementRef },
    dialModalRef: MatDialogRef<ShareDatasetTooltipComponent>
  ) {
    this.dialModalRef = dialModalRef;
    this.triggerElementRef = data.trigger;
    this.datasetName = this.data.datasetName;
    this.datasetProtocol = this.data.datasetProtocol;
    this.datasetId = this.data.datasetId;
    // TODO alignment
  }

  async ngOnInit() {
    // close dialog when leaving current page
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.dialModalRef.close();
      }
    });

    // Put element on top of button
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();
    // initial left corner of the tooltip
    let initialY = rect.bottom - 55;
    // offset based on window size and 410px for modal height
    let offset = 410 - (window.innerHeight - initialY);
    offset = offset > 0 ? offset : 0;
    let topVal = initialY - offset;

    matDialogConfig.position = {
      left: `${rect.left + 45}px`,
      top: `${topVal}px`
    };
    matDialogConfig.width = "300px";
    matDialogConfig.height = "400px";
    this.dialModalRef.updateSize(matDialogConfig.width, matDialogConfig.height);
    this.dialModalRef.updatePosition(matDialogConfig.position);

    this.itemSubscription = combineLatest(
      this.hiveSession.session,
      this.datasetService.getDatasets(),
      this.userService.getUsers()
    ).subscribe(([session, ds, u]: [IHiveSession, Dataset[], User[]]) => {
      this.zone.run(() => {
        // Only users that are not the current user
        var users = u.filter(user => user.hiveName != session.username);

        users.forEach( (user: User) => {
          var i: Item = this.items.find( (i: Item) => i.userId == user.id );
          if (!i) {
            i = new Item();
            this.items.push(i);
          }
          
          i.name = user.name;
          i.userId = user.id;
          
          var dsSharedWithUser = ds.filter( (dataset: Dataset) => dataset.id == this.datasetId && !!dataset.shares.find( (share: string) => share == user.id ) );
          
          i.shared = dsSharedWithUser.length > 0;
          i.someshared =
            i.shared && dsSharedWithUser.length != 1;
        });
      });
    });
  }

  ngOnDestroy() {
    if (this.itemSubscription) {
      this.itemSubscription.unsubscribe();
    }
  }

  // share study with user on checkbox click
  onUserClick(item: Item, event: MouseEvent) {
    event.preventDefault();
    
    // We're only going to allow an action to invoked once when in
    //  an intermediate state per session.
    if (item.someshared && this.userActionSent[item.userId]) {
      return;
    }
    
    this.userActionSent[item.userId] = true;
    
    var action: string;
    
    if (item.someshared || !item.shared) {
      action = "shareDatasetById";
    } else {
      action = "unshareDatasetById";
    }
    
    const beeClient = this.hiveBee.beeClient.getValue();
    beeClient.actions.send(action, [this.datasetId, item.userId]);
    
    item.someshared = true;
  }
}
