import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HiveBeeAngularModule, HiveBeeService, EnvironmentService } from 'hive-bee-angular';

import { appRoutingModule } from './app.routing';
import { ErrorInterceptor } from './_helpers';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { AlertComponent, AiStatusComponent, NewDataSetComponent } from './_components';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './_components/header/header.component';
import { AdminComponent } from './admin/admin.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { SideBarComponent } from './_components/side-bar/side-bar.component';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import { ShareTooltipComponent } from './_components/share-tooltip/share-tooltip.component';
import { ShareDatasetTooltipComponent } from './_components/share-dataset-tooltip/share-dataset-tooltip.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import { DatasetsComponent } from './datasets/datasets.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { ReviewerDashboardComponent } from './reviewer-dashboard/reviewer-dashboard.component';
import { ReviewTooltipComponent } from './_components/review-tooltip/review-tooltip.component';
import {MatMenuModule} from '@angular/material/menu';
import { MatCardModule } from '@angular/material';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ForgotPasswordComponent } from './_components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './_components/reset-password/reset-password.component';
import { ChangePasswordComponent } from './_components/change-password/change-password.component';
import { SearchBarComponent } from './_components/search-bar/search-bar.component';


import { ChestGeneralScoringPanelComponent } from './_components/scoringPanels/chestGeneral/chestGeneral.component';
import { OsteoArthritisKneeScoringPanelComponent } from "./_components/scoringPanels/osteoArthritisKnee/osteoArthritisKnee.component";

// app initializer to fetch the environment.json file generated at build time
export function hiveBeeInitializer(hiveBee: HiveBeeService, environmentService: EnvironmentService) {
 return async () => {
   const environment = await environmentService.fetchData();
   hiveBee.storageNamespaces = environment.storage.namespaces;
   hiveBee.initialize(environment);
 };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    AlertComponent,
    AiStatusComponent,
    NewDataSetComponent,
    HeaderComponent,
    AdminComponent,
    SideBarComponent,
    ShareTooltipComponent,
    ShareDatasetTooltipComponent,
    DatasetsComponent,
    ReviewerDashboardComponent,
    ReviewTooltipComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    SearchBarComponent,
    ChestGeneralScoringPanelComponent,
    OsteoArthritisKneeScoringPanelComponent,
  ],
  imports: [
        MatCardModule,
        MatExpansionModule,
        FormsModule,
        MatCheckboxModule,
        MatIconModule,
        MatSidenavModule,
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        appRoutingModule,
        BrowserAnimationsModule,
        HiveBeeAngularModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatButtonModule, // FIXME Buttons are not working properly with the styling
        MatDialogModule,
        MatSelectModule,
        MatMenuModule,
        MatSlideToggleModule
  ],
  providers: [
    // Provider to initialize the bee before the Angular app starts
    {
      provide: APP_INITIALIZER,
      useFactory: hiveBeeInitializer,
      multi: true,
      deps: [HiveBeeService, EnvironmentService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  entryComponents: [
    ResetPasswordComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    AiStatusComponent,
    NewDataSetComponent,
    ShareTooltipComponent,
    ShareDatasetTooltipComponent,
    ReviewTooltipComponent,
    ChestGeneralScoringPanelComponent,
    OsteoArthritisKneeScoringPanelComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
